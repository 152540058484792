import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Category } from 'src/model/Category';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { CategoryBasic, FormInputCategory } from '../../types';
import { CategoryCarouselManagerUI } from './ui';
import { toast } from 'react-toastify';

export interface NameFiles {
  [key: string]: string;
}

export const CategoriesManagerScreen = () => {
  //   const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const [formNameFilesCategory, setFormNameFilesCategory] = useState<NameFiles>(
    {}
  );
  const [categories, setCategories] = useState<Category[]>([]);

  const getCategories = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Category[]>('/category/find-all');
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataCategory,
    formErrors: formErrorsCategory,
    setErrors: setErrorsCategory,
    onChangeFormInput: onChangeFormInputCategory,
    isFormValid: isFormValidCategory,
    resetForm: resetFormCategory,
  } = useForm({
    initialData: {
      name: '',
      image_url: '',
    },
    validators: {
      name: [validators.required],
    },
  });

  const handleRegisterCategory = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidCategory()) {
        dispatch(setLoading(true));
        const payload: CategoryBasic = {
          name: formDataCategory[FormInputCategory.name],
          image: formDataCategory[FormInputCategory.image_url],
        };

        const response = await api.post<Category>('/category', payload);
        if (response.status === 201 || response.status === 200) {
          getCategories();
          resetFormCategory();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      } else {
        toast.error('Verifique os dados');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        resetFormCategory();
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorsCategory({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png|webp)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFilesCategory({
              ...formNameFilesCategory,
              [inputName]: file.name,
            });
            onChangeFormInputCategory(inputName)('');
            onChangeFormInputCategory(inputName)(base64);
          }
        };
      } else {
        setErrorsCategory({
          [inputName]: ['O formato deve ser .jpg, .jpeg, .png ou .webp'],
        });
      }
    };

  const handleEditCategory = (id: string) => {
    console.log(id);
  };

  const handleDeleteCategory = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Category[]>(`/category/delete/${id}`);
      if (response.status === 200) {
        toast.success('Categoria excluida com sucesso');
        getCategories();
      }
    } catch (error) {
      console.error('Erro ao excluir categoria:', error);
      toast.error('Algo deu Errado');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <CategoryCarouselManagerUI
      categories={categories}
      formData={formDataCategory}
      formErrors={formErrorsCategory}
      formNameFiles={formNameFilesCategory}
      onChangeFormInput={onChangeFormInputCategory}
      onSubmitRegister={handleRegisterCategory}
      onChangeFormFileInput={handleOnChangeFileInput}
      onEditCategory={handleEditCategory}
      onDeleteCategory={handleDeleteCategory}
    />
  );
};
