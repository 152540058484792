// Logic Component
import React, { useEffect, useState } from 'react';
import ProductApprovalUI from './ui';
import { Product } from 'src/model/Product';
import { api } from 'src/services/api';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';

export const ProductApprovalScreen: React.FC = () => {
  const [products, setProducts] = useState<Product[]>();
  const dispatch = useDispatch();

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product[]>('/product/inactive');

      if (response.status === 200) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleActiveProduct = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get(`/product/active/${id}`);

      if (response.status === 200) {
        getProduct();
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleExcludedProduct = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get(`/product/inactive/${id}`);
      if (response.status === 200) {
        getProduct();
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  return products ? (
    <ProductApprovalUI
      products={products}
      onActiveProduct={handleActiveProduct}
      onExcludedProduct={handleExcludedProduct}
    />
  ) : (
    <>Semprodutos para aprovar</>
  );
};
