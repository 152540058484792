import React from 'react';
// import Banner from 'src/components/Banner';
import { BannerList } from 'src/components/BannerList';
import { CarouselBannerUI } from 'src/components/CarouselBanner';
import { CategoryCarousel } from 'src/components/CategoryCarousel';
import { ProductCard3 } from 'src/components/ProductCard3';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { Banners } from 'src/model/Banners';
import { Category } from 'src/model/Category';
import { Product } from 'src/model/Product';

interface HomeUIProps {
  product: Product[];
  categories: Category[];
  banners: Banners[];
  bannerService: Banners[];
  bannerImages: Banners[];
  bannerMidleImages: Banners[];
  valuePrice: (product: Product, discount: boolean) => string;
  goCategory: (id: string) => void;
  goSlide?: (id: string) => void;
}

export const HomeUI: React.FC<HomeUIProps> = ({
  product,
  categories,
  banners,
  bannerService,
  bannerImages,
  bannerMidleImages,
  goCategory,
  goSlide,
  valuePrice,
}) => {
  return (
    <AuthWrapper>
      <div className="w-full grid grid-cols-1 justify-items-center">
        <div className="md:block w-full">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={banners}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>
        <div className="hidden w-full">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={banners}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>

        <div className="w-full flex flex-wrap justify-center lg:flex-nowrap items-center mb-2 bg-gray-200 max-h-full">
          <div className="w-full flex justify-center p-6 md:mt-0">
            {BannerList && <BannerList bannerListData={bannerService} />}
          </div>
          <div className="w-full h-[10rem] md:w-[33rem]">
            {CarouselBannerUI && (
              <CarouselBannerUI
                slides={bannerImages}
                hideIndicators={true}
                hideControls={true}
                goSlide={goSlide}
                className="w-full h-[10rem] md:w-[33rem] object-cover"
              />
            )}
          </div>
        </div>

        <div className="max-w-[1152px] flex justify-center items-center">
          {categories && categories.length > 0 && (
            <CategoryCarousel categories={categories} goCategory={goCategory} />
          )}
        </div>
        <div className="max-w-[1152px] mt-5 md:mt-0 justify-items-center">
          <div className="w-full h-[5rem] px-2 mb-10 md:mb-5 md:w-full md:h-[200px] lg:w-full lg:h-[160px]">
            {/* 1152px por 180px */}
            {/* {bannerMidleImages && bannerMidleImages.length > 0 && (
              <Banner images={bannerMidleImages} interval={3000} />
            )} */}
            {CarouselBannerUI && (
              <CarouselBannerUI
                slides={bannerMidleImages}
                hideIndicators={true}
                hideControls={true}
                goSlide={goSlide}
                className="w-full h-[5rem] md:w-full md:h-[200px] lg:w-full lg:h-[160px]"
              />
            )}
          </div>

          {product && (
            <ProductCard3 products={product} valuePrice={valuePrice} />
          )}
        </div>
      </div>
    </AuthWrapper>
  );
};
