// Logic Component
import React from 'react';
import NotificationUI from './ui';

// Interface para as notificações
interface Notification {
  id: number;
  type: string;
  message: string;
  orderId?: string;
  date: string;
  time: string;
  detailsUrl?: string;
  actionLabel?: string;
}

// Lista inicial de notificações
const notifications: Notification[] = [
  {
    id: 1,
    type: 'Pedido concluído',
    message: 'Ei! Você ainda não avaliou seu pedido!',
    orderId: '241001EBWHQ0KP',
    date: '15/10/2024',
    time: '03:18',
    actionLabel: 'Avaliar Agora',
  },
  {
    id: 2,
    type: 'Nova oferta',
    message:
      'terravitta1 fez uma oferta para a solicitação de reembolso. Se aceitar, você receberá o valor de R$10,97.',
    date: '14/10/2024',
    time: '17:38',
    detailsUrl: '#',
    actionLabel: 'Ver Detalhes',
  },
  {
    id: 3,
    type: 'Devolução aprovada',
    message:
      'Sua solicitação de devolução foi aprovada. Siga as instruções para devolução até 18/10/2024.',
    orderId: '2410140GH2MMSFM',
    date: '13/10/2024',
    time: '22:21',
    detailsUrl: '#',
    actionLabel: 'Ver Detalhes',
  },
  {
    id: 4,
    type: 'Pacote entregue',
    message: 'Seu pacote foi entregue com sucesso. Pedido: BR240609433212YS',
    orderId: '241005P45CGP72',
    date: '11/10/2024',
    time: '15:10',
    detailsUrl: '#',
    actionLabel: 'Ver Detalhes',
  },
];

export const NotificationScreenAdm: React.FC = () => {
  return <NotificationUI notifications={notifications} />;
};
