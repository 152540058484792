import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Product, ProductStatus } from 'src/model/Product';

interface ProductApprovalUIProps {
  products: Product[];
  onActiveProduct: (id: string) => void;
  onExcludedProduct: (id: string) => void;
}

const ProductApprovalUI: React.FC<ProductApprovalUIProps> = ({
  products,
  onActiveProduct,
  onExcludedProduct,
}) => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product) => (
          <div
            key={product.id}
            className="border border-gray-100 rounded-sm shadow-sm p-4 bg-white relative"
          >
            <div
              className={`absolute top-2 right-2 w-4 h-4 rounded-full ${
                product.status === ProductStatus.ACTIVE
                  ? 'bg-green-500'
                  : 'bg-red-500'
              }`}
              title={
                product.status === ProductStatus.ACTIVE
                  ? 'Aprovado'
                  : 'Desaprovado'
              }
            ></div>
            {product.imagens && product.imagens[0] ? (
              <img
                src={product.imagens[0]}
                alt={product.name}
                className="w-full h-40 object-cover mb-4"
              />
            ) : (
              <div className="w-full h-40 bg-gray-200 flex items-center justify-center mb-4">
                <span className="text-gray-500">Sem imagem</span>
              </div>
            )}
            <h2 className="font-semibold text-lg mb-1 text-gray-800">
              {product.name}
            </h2>
            <h2 className="font-bold text-lg mb-1 text-gray-800">
              {product.price.price}
            </h2>
            <p className="text-sm text-gray-500 mb-1">
              Estoque:{' '}
              <span className="text-red-500 font-semibold">
                {product.stock}
              </span>
            </p>
            <div className="w-full flex justify-around items-center space-x-2">
              <button
                onClick={() => onActiveProduct(product.id)}
                className="flex items-center text-md transition-transform duration-300 transform hover:scale-105 text-green-500 hover:text-green-600"
              >
                <FaCheck className="mr-1" /> Aprovar
              </button>
              <button
                onClick={() => onExcludedProduct(product.id)}
                className={
                  'flex items-center text-md transition-transform duration-300 transform hover:scale-105 text-red-500 hover:text-red-600'
                }
              >
                <FaTimes className="mr-1" /> Desaprovar
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductApprovalUI;
