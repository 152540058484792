import React from 'react';
import {
  FaCheck,
  FaPaperPlane,
  FaHourglassHalf,
  FaTruck,
  FaHome,
} from 'react-icons/fa';
import { StatusDeliveryStep } from 'src/model/StatusDeliveryTypes';

interface StatusTrackerProps {
  steps: StatusDeliveryStep[];
}

// Mapping statusType to specific icons
const statusIcons = {
  preparation: <FaCheck />,
  shipped: <FaPaperPlane />,
  inTransit: <FaHourglassHalf />,
  outForDelivery: <FaTruck />,
  delivered: <FaHome />,
};

export const StatusTracker: React.FC<StatusTrackerProps> = ({ steps }) => {
  return (
    <div className="relative w-full p-4">
      {/* Background line for the overall progress */}
      <div
        className="absolute top-1/2 left-32 h-0.5 w-[50%] bg-gray-300 z-0"
        style={{ transform: 'translateY(-50%)' }}
      />

      <div className="relative flex justify-between items-center w-full">
        {steps.map((step, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center w-1/5 z-10"
          >
            {/* Icon above the line */}
            <div
              className={`flex items-center justify-center w-12 h-12 rounded-full border-4 mb-2
                ${step.isCompleted ? 'border-green-500 bg-green-500 text-white' : ''}
                ${step.isCurrent ? 'border-green-300 bg-green-100 text-green-500' : ''}
                ${!step.isCompleted && !step.isCurrent ? 'border-gray-300 text-gray-400' : ''}
              `}
            >
              {statusIcons[step.statusType]}
            </div>

            {/* Connecting line */}
            {index < steps.length - 1 && (
              <div
                className={`absolute top-1/2 -right-1/2 h-0.5 w-full ${step.isCompleted || step.isCurrent ? 'bg-green-500' : 'bg-gray-300'}`}
                style={{ transform: 'translateY(-50%)' }}
              ></div>
            )}

            {/* Label and Date/Time below the line */}
            <div className="text-center mt-4" style={{ minHeight: '48px' }}>
              <p className="text-xs font-semibold">{step.label || '\u00A0'}</p>{' '}
              {/* Ensures space if no label */}
              {step.date && (
                <p className="text-xs text-gray-500">{step.date}</p>
              )}
              {step.time && (
                <p className="text-xs text-gray-500">{step.time}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
