import { BannerType } from 'src/model/BannerType';

export enum FormInputCard {
  title = 'title',
  imageSrc = 'imageSrc',
}

export enum FormInputCategory {
  name = 'name',
  image_url = 'image_url',
}
export interface CategoryBasic {
  name: string;
  image: string;
}
export enum FormInputAppQrCode {
  imageUrl = 'imageUrl',
  link = 'link',
}

export enum UserRole {
  admin = 'admin',
  user = 'user',
  seller = 'seller',
}
export enum SocialPlatform {
  instagram = 'instagram',
  whatsapp = 'whatsapp',
  tiktok = 'tiktok',
  youtube = 'youtube',
}

export enum FormInputVideo {
  videoUrl = 'videoUrl',
  title = 'title',
  subtitle = 'subtitle',
  position = 'position',
}

export enum FormInputRate {
  rate = 'rate',
}
export interface VideoBasic {
  link: string;
  description: string;
  name: string;
  position: string;
}

export enum FormInputServicesBanner {
  name = 'name',
  imageSrc = 'imageSrc',
}

export enum FormInputImage {
  imageUrl = 'imageUrl',
  imageName = 'imageName',
}

export enum FormInputDiscount {
  discount = 'discount',
}
export enum FormInputBannerPrimaryMobile {
  altTextBannerPrimaryMobile = 'altTextBannerPrimaryMobile',
  imageBannerPrimaryMobile = 'imageBannerPrimaryMobile',
  categoryBannerPrimaryMobile = 'categoryBannerPrimaryMobile',
}

export enum FormInputBannerSecondaryBanner {
  altTextSecondaryBanner = 'altTextSecondaryBanner',
  imageSecondaryBanner = 'imageSecondaryBanner',
  linkSecondaryBanner = 'linkSecondaryBanner',
}
export enum FormInputBannerHome {
  altTextBannerHome = 'altTextBannerHome',
  imageBannerHome = 'imageBannerHome',
  imageBannerHomeMobile = 'imageBannerHomeMobile',
  linkBannerHome = 'linkBannerHome',
}

export enum FormInputBannerService {
  altTextService = 'altTextService',
  imageService = 'imageService',
  linkService = 'linkService',
}
export enum FormInputBannerMidleBanner {
  altTextMidleBanner = 'altTextMidleBanner',
  imageMidleBanner = 'imageMidleBanner',
  linkMidleBanner = 'linkMidleBanner',
}

export interface BannerBasic {
  altText: string;
  image: string;
  imageMobile?: string;
  link: string;
  privatetype: BannerType;
}

export enum FormContentTextarea {
  HTML = 'html',
  CSS = 'css',
}

export enum FormContentTextareaAdvertising {
  HTML = 'html',
  CSS = 'css',
}
export interface PrivacyPolicyContent {
  id: string;
  text: string;
  contentType: FormContentTextarea;
}

export enum FormInputSocialMedia {
  instagram = 'instagram',
  whatsapp = 'whatsapp',
  tiktok = 'tiktok',
  youtube = 'youtube',
}
