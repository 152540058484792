// SocialLinksManagerScreen.tsx
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useForm from 'src/hooks/useForm';
import { SocialMedia } from 'src/model/SocialMedia';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { FormInputSocialMedia } from '../../types';
import { SocialLinksManager } from './ui';

export const SocialLinksManagerScreen: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const [id, setId] = useState<string>();

  const {
    formData: formDataSocialMedia,
    formErrors: formErrorsSocialMedia,
    setErrors: setErrorsSocialMedia,
    onChangeFormInput: onChangeFormInputSocialMedia,
    isFormValid: isFormValidSocialMedia,
    resetForm: resetFormSocialMedia,
  } = useForm({
    initialData: {
      instagram: '',
      whatsapp: '',
      tiktok: '',
      youtube: '',
    },
  });

  const getSocialMedia = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<SocialMedia>('/social-media/find-all');
      if (response.status === 200) {
        const socialMedia = response.data;
        onChangeFormInputSocialMedia(FormInputSocialMedia.instagram)(
          socialMedia.instagram
        );
        onChangeFormInputSocialMedia(FormInputSocialMedia.whatsapp)(
          socialMedia.whatsapp
        );
        onChangeFormInputSocialMedia(FormInputSocialMedia.tiktok)(
          socialMedia.tiktok
        );
        onChangeFormInputSocialMedia(FormInputSocialMedia.youtube)(
          socialMedia.youtube
        );
        setId(socialMedia.id);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRegisterSocialMedia = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidSocialMedia()) {
        dispatch(setLoading(true));
        const payload = {
          id: id,
          instagram: formDataSocialMedia[FormInputSocialMedia.instagram],
          whatsapp: formDataSocialMedia[FormInputSocialMedia.whatsapp],
          tiktok: formDataSocialMedia[FormInputSocialMedia.tiktok],
          youtube: formDataSocialMedia[FormInputSocialMedia.youtube],
        };
        const response = await api.post<SocialMedia>(
          '/social-media/update',
          payload
        );
        if (response.status === 201 || response.status === 200) {
          getSocialMedia();
          resetFormSocialMedia();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      } else {
        toast.error('Verifique os dados');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorsSocialMedia({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getSocialMedia();
    // eslint-disable-next-line
  }, []);

  return (
    <SocialLinksManager
      formData={formDataSocialMedia}
      formErrors={formErrorsSocialMedia}
      onChangeFormInput={onChangeFormInputSocialMedia}
      onSubmitRegisterSocialMedia={handleRegisterSocialMedia}
    />
  );
};
