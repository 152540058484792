// screens/PlatformProfitControllerScreen.tsx
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Rate } from 'src/model/Rate';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { FormInputRate } from '../../types';
import { PlatformProfitControllerUI } from './ui';

export const PlatformProfitControllerScreen: React.FC = () => {
  const [rate, setRate] = useState<Rate>({
    value: 0,
  });
  const [id, setID] = useState<string>();
  const dispatch = useDispatch();

  const getRate = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Rate>('/rate/find-all');
      if (response.status === 200) {
        const rate = response.data.value ? response.data.value.toString() : '0';

        onChangeFormInputRate(FormInputRate.rate)(rate);
        setRate(response.data);
        setID(response.data.id);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataRate,
    formErrors: formErrorsRate,
    setErrors: setErrorsRate,
    onChangeFormInput: onChangeFormInputRate,
    isFormValid: isFormValidRate,
    resetForm: resetFormRate,
  } = useForm({
    initialData: {
      rate: '',
    },
    validators: {
      rate: [validators.required],
    },
  });

  const handleRegisterRate = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRate()) {
        dispatch(setLoading(true));
        const payload = {
          id: id,
          value: formDataRate[FormInputRate.rate],
        };
        const response = await api.post<Rate>('/rate/update', payload);
        if (response.status === 201 || response.status === 200) {
          getRate();
          resetFormRate();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      } else {
        toast.error('Verifique os dados');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorsRate({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getRate();
    // eslint-disable-next-line
  }, []);

  return (
    <PlatformProfitControllerUI
      rate={rate}
      formData={formDataRate}
      formErrors={formErrorsRate}
      onSubmitPorcentagem={handleRegisterRate}
      onChangeFormInput={onChangeFormInputRate}
    />
  );
};
