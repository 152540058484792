import React from 'react';
import { AddressManagerScreen } from 'src/components/AddressManager';
import { ChatComponent } from 'src/components/Chat';
import { NotificationComponentScreen } from 'src/components/NotificationComponent';
import OrderPreparation from 'src/components/OrderPreparation';
import { OrderTracking } from 'src/components/OrderTracking';
import { PrivacySettings } from 'src/components/PrivacySettings';
// import { HorizontalProductCard } from 'src/components/ProductHorizontalCard';
import ProductManager from 'src/components/ProductManager';
import { ProfileSideMenu } from 'src/components/ProfileSideMenu';
import { StoreProfileManager } from 'src/components/StoreProfileManager';
import { UserProfileManagerScreen } from 'src/components/UserProfileManager';
import { CartScreen } from 'src/features/checkout/screens/Cart';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { CreateProductScreen } from 'src/features/createProduct/screens/CreateProduct';
import { CreditCardPaymentAddScreen } from 'src/features/userProfile/components/CreditCards';
// import { Product } from 'src/model/Product';
import Client from 'src/model/User';

interface UserProfileUIProps {
  user: Client;
}

// const productList: Product[] = [];

export const UserProfileUI: React.FC<UserProfileUIProps> = ({ user }) => {
  const chats = [
    {
      id: '1',
      name: 'Ana Silva',
      lastMessage: 'Oi, como você está?',
      timestamp: 'Hoje às 14:30',
      unread: 2,
      messages: [
        { sender: 'Ana Silva', content: 'Oi, como você está?' },
        { sender: 'Você', content: 'Estou bem, e você?' },
      ],
    },
    {
      id: '2',
      name: 'João Pereira',
      lastMessage: 'Vamos marcar para amanhã?',
      timestamp: 'Ontem às 18:45',
      unread: 1,
      messages: [
        { sender: 'João Pereira', content: 'Vamos marcar para amanhã?' },
      ],
    },
    {
      id: '3',
      name: 'Maria Oliveira',
      lastMessage: 'Parabéns pelo projeto!',
      timestamp: '20/01 às 10:15',
      unread: 0,
      messages: [
        { sender: 'Maria Oliveira', content: 'Parabéns pelo projeto!' },
        { sender: 'Você', content: 'Muito obrigado!' },
      ],
    },
  ];
  const menuItems = [
    {
      title: 'Chat',
      content: <ChatComponent chats={chats} />,
    },
    {
      title: 'Alterar Dados Pessoais',
      content: <UserProfileManagerScreen userData={user} />,
    },
    {
      title: 'Alterar Dados Loja',
      content: <StoreProfileManager />,
    },
    {
      title: 'Criar Produto',
      content: <CreateProductScreen />,
    },

    {
      title: 'Carrinho',
      content: <CartScreen auth={false} showTitle={false} />,
    },
    {
      title: 'Acompanhar Pedidos',
      content: <OrderTracking />,
    },
    {
      title: 'Cartões',
      content: <CreditCardPaymentAddScreen />,
    },
    {
      title: 'Endereços',
      content: <AddressManagerScreen />,
    },
    {
      title: 'Notificações',
      content: <NotificationComponentScreen />,
    },
    {
      title: 'Gerenciar Produtos',
      content: <ProductManager />,
    },
    {
      title: 'Vendas',
      content: <OrderPreparation />,
    },
    {
      title: 'Config de Privacidade',
      content: <PrivacySettings />,
    },

    // Adicione mais itens conforme necessário
  ];

  return (
    <AuthWrapper>
      <div className="container p-0 m-4 bg-white rounded-lg shadow-sm w-full">
        {/* Banner */}
        <div className="relative bg-white h-36 sm:h-64 flex items-center justify-center rounded-t-lg">
          <img
            src="https://via.placeholder.com/100"
            alt={`${user.name} Logo`}
            className="w-32 h-32 rounded border-4 border-white shadow-lg absolute top-[45%] sm:top-[65%]"
          />
        </div>

        {/* User Info */}
        <div className="flex flex-col items-center justify-center align-middle mt-16 text-center ">
          <div className="w-[90%] sm:w-[60%] flex justify-center items-center  rounded  bg-[#eeeeee86]  px-2 py-4">
            {/* <div>87.0% Avaliação</div> */}
            <div className="text-center font-bold">{user.name}</div>
            {/* <div>5.1K Vendas</div> */}
          </div>
          <div className=" mt-6 ">{/* <span>descrição</span> */}</div>
        </div>

        <div className="m-0">
          <ProfileSideMenu user={user} menuItems={menuItems} />
        </div>
      </div>
    </AuthWrapper>
  );
};
