import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { CarouselBannerUI } from 'src/components/CarouselBanner';
import { ProductCard3 } from 'src/components/ProductCard3';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { Banners } from 'src/model/Banners';
import { Product } from 'src/model/Product';

interface ProductsSearchUIProps {
  product: Product[];
  banners: Banners[];
  goSlide?: (id: string) => void;
  valuePrice: (product: Product, discount: boolean) => string;
}

interface Params {
  keyword: string;
}

export const ProductsSearchUI: React.FC<ProductsSearchUIProps> = ({
  product,
  banners,
  goSlide,
  valuePrice,
}) => {
  const { keyword } = useParams<Params>();

  const [freeShipping, setFreeShipping] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [location, setLocation] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const [filteredProducts, setFilteredProducts] = useState<Product[]>(product);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const applyFilters = () => {
    let filtered = [...product];

    if (freeShipping) {
      filtered = filtered.filter((p) => p.freeShipping);
    }

    if (brandName) {
      filtered = filtered.filter((p) =>
        p.brand.name.toLowerCase().includes(brandName.toLowerCase())
      );
    }

    if (location) {
      filtered = filtered.filter((p) =>
        p.localization?.toLowerCase().includes(location.toLowerCase())
      );
    }

    if (minPrice) {
      filtered = filtered.filter((p) =>
        p.grade?.some((grade) =>
          grade.gradeSizes?.some(
            (gradeSize) => gradeSize.price?.price >= minPrice
          )
        )
      );
    }

    // Filtro por preço máximo
    if (maxPrice) {
      filtered = filtered.filter((p) =>
        p.grade?.some((grade) =>
          grade.gradeSizes?.some(
            (gradeSize) => gradeSize.price?.price <= maxPrice
          )
        )
      );
    }

    setFilteredProducts(filtered);
    setIsFilterApplied(true);
  };

  const clearFilters = () => {
    setFreeShipping(false);
    setBrandName('');
    setLocation('');
    setMinPrice(0);
    setMaxPrice(0);
    setFilteredProducts(product);
    setIsFilterApplied(false);
  };

  const handleFilterSubmit = () => {
    applyFilters();
  };

  const productsToShow = product.filter((p) =>
    p.name.toLowerCase().includes(keyword?.toLowerCase() || '')
  );

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setter(value === '' ? 0 : parseFloat(value));
    }
  };

  useEffect(() => {
    setFilteredProducts(product);
    clearFilters();
    setIsFilterApplied(false);
  }, [keyword, product]);

  return (
    <AuthWrapper>
      <>
        <div className="hidden w-full">
          {CarouselBannerUI && (
            <CarouselBannerUI
              slides={banners}
              hideIndicators={false}
              hideControls={false}
              goSlide={goSlide}
              className="aspect-[4/2] md:aspect-[10/3]"
            />
          )}
        </div>

        <div className="flex flex-col justify-between w-full xl:flex-none xl:hidden">
          <div className="flex justify-between px-4 pt-2">
            <Sheet>
              <SheetTrigger className="flex text-lg">
                <FiFilter className="mt-[0.30rem]" />
                <h1>Filtrar</h1>
              </SheetTrigger>
              <SheetContent className="bg-[#eeeeee] animate-enter-from-down flex flex-col justify-between p-0">
                <div className="p-4">
                  <SheetTitle className="font-semibold text-xl">
                    Filtrar Produtos
                  </SheetTitle>
                  <div className="border-t-2 border-gray-300 my-4" />
                  <div className="space-y-2">
                    <label className="flex items-center text-gray-500">
                      <input
                        type="checkbox"
                        checked={freeShipping}
                        onChange={(e) => setFreeShipping(e.target.checked)}
                        className="mr-1 mt-[0.05rem]"
                      />
                      Frete Grátis
                    </label>
                    <hr />
                    <div className="flex flex-col">
                      <label htmlFor="brand" className="text-gray-500">
                        Marca
                      </label>
                      <input
                        type="text"
                        id="brand"
                        placeholder="Marca"
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                        className="w-full px-2 py-1 rounded text-black"
                      />
                    </div>
                    <hr />
                    <div className="flex flex-col">
                      <label htmlFor="localization" className="text-gray-500">
                        Localização
                      </label>
                      <input
                        type="text"
                        id="localization"
                        placeholder="Localização"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className="w-full px-2 py-1 rounded text-black"
                      />
                    </div>
                    <hr />
                    <div className="flex flex-col">
                      <span className="text-gray-500">Preço</span>
                      <div className="flex gap-2 items-center">
                        <input
                          type="text"
                          placeholder={
                            minPrice === 0
                              ? 'Preço Mínimo'
                              : minPrice.toString()
                          }
                          value={minPrice === 0 ? '' : minPrice}
                          onChange={(e) => handlePriceChange(e, setMinPrice)}
                          className="w-full px-2 py-1 rounded text-black"
                        />
                        -
                        <input
                          type="text"
                          placeholder={
                            maxPrice === 0
                              ? 'Preço Máximo'
                              : maxPrice.toString()
                          }
                          value={maxPrice === 0 ? '' : maxPrice}
                          onChange={(e) => handlePriceChange(e, setMaxPrice)}
                          className="w-full px-2 py-1 rounded text-black"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <SheetFooter>
                  <div className="flex w-full">
                    <button
                      onClick={clearFilters}
                      className="px-4 py-2 bg-[#fff1f1] text-[#F14A3E] w-full border border-red-400"
                    >
                      Limpar
                    </button>
                    <SheetClose asChild onClick={handleFilterSubmit}>
                      <button className="px-4 py-2 bg-[#F14A3E] text-white w-full border border-red-400">
                        Pronto!
                      </button>
                    </SheetClose>
                  </div>
                  {isFilterApplied && filteredProducts.length === 0 && (
                    <span className="text-sm text-red-400 font-semibold mt-3 text-center">
                      Nenhum produto foi encontrado com este filtro!
                    </span>
                  )}
                </SheetFooter>
              </SheetContent>
            </Sheet>
            <div>
              <Link to="/" className="underline">
                Voltar
              </Link>
            </div>
          </div>

          <div className="mt-5">
            {productsToShow.length === 0 ? (
              <div className="mt-5 text-center">
                <h2 className="text-lg sm:text-2xl font-semibold">
                  Nenhum produto encontrado para &quot;{keyword}&quot;
                </h2>

                <p className="text-sm sm:text-lg">
                  Por favor, tente outra busca ou verifique se o nome está
                  correto.
                </p>
              </div>
            ) : (
              <div
                className={`mt-5 ${filteredProducts.length !== 0 && 'flex-none hidden'}`}
              >
                <ProductCard3
                  products={productsToShow}
                  valuePrice={valuePrice}
                />
              </div>
            )}
            {/* Exibindo os produtos filtrados */}
            {filteredProducts.length !== 0 && (
              <div className="mt-5">
                <ProductCard3
                  products={filteredProducts}
                  valuePrice={valuePrice}
                />
              </div>
            )}
          </div>
        </div>

        <div className="hidden flex-none xl:flex gap-5 w-full container">
          <div className="flex flex-col px-4 pt-2">
            <div className="min-w-[18rem] max-w-[18rem] mt-5">
              <h1 className="flex gap-1">
                <FiFilter className="mt-[0.30rem]" />
                <span>Filtros</span>
              </h1>
              <div className="border-t-2 border-gray-300 mb-4" />
              <div className="space-y-2">
                <label className="flex items-center text-gray-500">
                  <input
                    type="checkbox"
                    checked={freeShipping}
                    onChange={(e) => setFreeShipping(e.target.checked)}
                    className="mr-1 mt-[0.05rem]"
                  />
                  Frete Grátis
                </label>
                <hr />
                <div className="flex flex-col">
                  <label htmlFor="brand" className="text-gray-500">
                    Marca
                  </label>
                  <input
                    type="text"
                    id="brand"
                    placeholder="Marca"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    className="w-full px-2 py-1 rounded text-black"
                  />
                </div>
                <hr />
                <div className="flex flex-col">
                  <label htmlFor="localization" className="text-gray-500">
                    Localização
                  </label>
                  <input
                    type="text"
                    id="localization"
                    placeholder="Localização"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="w-full px-2 py-1 rounded text-black"
                  />
                </div>
                <hr />
                <div className="flex flex-col">
                  <span className="text-gray-500">Preço</span>
                  <div className="flex gap-2 items-center">
                    <input
                      type="text"
                      placeholder={
                        minPrice === 0 ? 'Preço Mínimo' : minPrice.toString()
                      }
                      value={minPrice === 0 ? '' : minPrice}
                      onChange={(e) => handlePriceChange(e, setMinPrice)}
                      className="w-full px-2 py-1 rounded text-black"
                    />
                    -
                    <input
                      type="text"
                      placeholder={
                        maxPrice === 0 ? 'Preço Máximo' : maxPrice.toString()
                      }
                      value={maxPrice === 0 ? '' : maxPrice}
                      onChange={(e) => handlePriceChange(e, setMaxPrice)}
                      className="w-full px-2 py-1 rounded text-black"
                    />
                  </div>
                </div>
              </div>
            </div>
            {isFilterApplied && filteredProducts.length === 0 && (
              <span className="text-xs text-red-400 font-semibold mt-3">
                Nenhum produto foi encontrado com este filtro!
              </span>
            )}
            <div className="flex w-full gap-5 mt-3">
              <button
                onClick={clearFilters}
                className="py-1 bg-[#fff1f1] text-[#F14A3E] hover:bg-[#f3cfcf] w-full border border-red-400 rounded-md transition-all duration-300"
              >
                Limpar
              </button>
              <button
                className="py-1 bg-[#F14A3E] hover:bg-red-600 text-white w-full border border-red-400 rounded-md transition-all duration-300"
                onClick={handleFilterSubmit}
              >
                Filtrar
              </button>
            </div>
          </div>

          <div className="mt-5">
            {productsToShow.length === 0 ? (
              <div className="mt-5 text-center">
                <h2 className="text-lg sm:text-2xl font-semibold">
                  Nenhum produto encontrado para &quot;{keyword}&quot;
                </h2>

                <p className="text-sm sm:text-lg">
                  Por favor, tente outra busca ou verifique se o nome está
                  correto.
                </p>
              </div>
            ) : (
              <div
                className={`mt-5 ${filteredProducts.length !== 0 && 'flex-none hidden'}`}
              >
                <ProductCard3
                  products={productsToShow}
                  valuePrice={valuePrice}
                />
              </div>
            )}
            {/* Exibindo os produtos filtrados */}
            {filteredProducts.length !== 0 && (
              <div className="mt-5">
                <ProductCard3
                  products={filteredProducts}
                  valuePrice={valuePrice}
                />
              </div>
            )}
          </div>
        </div>
      </>
    </AuthWrapper>
  );
};
