// src/components/ProductCard3.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Product } from 'src/model/Product';
import { RatingStars2 } from '../StarRating2';

interface ProductCard3Props {
  products: Product[];
  valuePrice: (product: Product, discount: boolean) => string;
}

export const ProductCard3: React.FC<ProductCard3Props> = ({
  products,
  valuePrice,
}) => {
  return (
    <div className="flex flex-wrap justify-center gap-1">
      {products &&
        products.map((product) => (
          <Link
            to={`/product/${product.id}`}
            key={product.id}
            className="w-[11.7rem] md:w-56  bg-white rounded-lg shadow-md border border-gray-200 overflow-hidden"
          >
            {/* Contêiner Principal da Imagem e Rótulos */}
            <div className="relative overflow-hidden group">
              {/* Imagem do Produto */}
              <div className="h-32 md:h-40 bg-white flex items-center justify-center overflow-hidden">
                {product.imagens && product.imagens.length > 0 ? (
                  <img
                    src={product.imagens[0]}
                    alt={product.name}
                    className="md:p-0 p-1 h-full w-full object-contain transform transition-transform duration-300 group-hover:scale-110"
                  />
                ) : (
                  <div className="text-gray-400 text-sm">
                    Imagem indisponível
                  </div>
                )}
              </div>

              {/* Contêiner de Rótulos no Topo */}
              <div className="absolute inset-0 flex justify-between items-start">
                {/* Flag (como "Novo", "Promoção") */}
                {product.tags && (
                  <div className="bg-red-500 text-white text-xs px-2 py-1">
                    {product.tags[0]}
                  </div>
                )}
                {/* Shipping Label na Parte Inferior
                {product.shippingLabel && (
                  <div className="absolute bottom-0 left-0 bg-blue-600 text-white text-xs font-bold rounded-tr-lg px-2 py-1">
                    {product.shippingLabel}
                  </div>
                )} */}
              </div>
            </div>

            {/* Informações do Produto */}
            <div className="p-2">
              {/* Título do Produto com truncamento após duas linhas */}
              <h2
                className="text-sm font-semibold text-gray-800 leading-tight overflow-hidden"
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2, // Limita o texto a no máximo 2 linhas
                  overflow: 'hidden',
                }}
                title={product.name} // Exibe o nome completo ao passar o mouse
              >
                {product.name}
              </h2>
              {product.grade && product.promotion !== false ? (
                <div
                  className={`flex flex-col ${
                    valuePrice(product, true).length +
                      valuePrice(product, false).length >
                    22
                      ? 'flex-col'
                      : 'sm:flex-row sm:items-center sm:space-x-2'
                  } mt-2`}
                >
                  {/* Preço Promocional */}
                  <span className="text-red-500 font-bold text-lg">
                    {valuePrice(product, true)}
                  </span>
                  {/* Preço Original */}
                  <span className="text-gray-500 text-sm line-through">
                    {valuePrice(product, false)}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mt-2">
                  <span className="text-red-500 font-bold text-lg">
                    {valuePrice(product, false)}
                  </span>
                </div>
              )}

              {/* Avaliação e Quantidade Vendida */}
              <div className="flex flex-wrap items-center space-x-1 mt-2">
                <RatingStars2
                  rating={product.ratings ? product.ratings.length : 0}
                  width="1.1rem"
                  height="1.1rem"
                />
                {/* Passa o rating como prop */}
                <span className="text-gray-600 text-sm">
                  {product.totalSale} vendidos
                </span>
              </div>

              {/* Localização */}
              <div className="text-gray-500 text-xs mt-2">
                {product.localization}
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};
