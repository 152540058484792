import React, { SelectHTMLAttributes } from 'react';

export interface OptionProps {
  value?: string | number;
  label: string;
  title?: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  label?: string;
  error?: string | null | undefined;
  containerClassName?: string; // Classe para o contêiner
  labelClassName?: string; // Classe para o label
  selectClassName?: string; // Classe para o select
  optionClassName?: string; // Classe para as options
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: OptionProps[];
}

export const Select: React.FC<SelectProps> = ({
  name,
  value,
  placeholder,
  error,
  label,
  containerClassName,
  labelClassName,
  selectClassName,
  optionClassName,
  onBlur,
  onChange,
  options,
}) => (
  <div className={`relative space-y-1.5 ${containerClassName}`}>
    {label && (
      <label
        htmlFor={name}
        className={`block  text-gray-500 text-base font-regular ${labelClassName}`} // Aplicando className do label
      >
        {label}
      </label>
    )}
    <select
      name={name}
      aria-invalid={error ? 'true' : 'false'}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      className={`block appearance-none  w-full border rounded-lg text-input text-base leading-tight focus:outline-none ${
        error ? 'border-red-500' : 'border-none'
      } ${selectClassName}`} // Aplicando className do select
    >
      <option value="" disabled className={optionClassName}>
        {placeholder ? placeholder : 'Selecione...'}
      </option>
      {options.map((item) => (
        <option
          key={item.value}
          value={item.value}
          className={optionClassName}
          title={item.title || item.label}
        >
          {item.label}
        </option>
      ))}
    </select>
    {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
  </div>
);
