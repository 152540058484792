import { Product } from 'src/model/Product';

export enum FormInputReview {
  description = 'description',
}

export interface ProductReviewBasic {
  score: number;
  description: string;
  product: Product;
}
