import { ShoppingCartIcon } from 'lucide-react';
import React from 'react';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import { Product } from 'src/model/Product';
import Client from 'src/model/User';
import { RatingStars2 } from '../StarRating2';
import { SelectGrade } from 'src/features/product/screens/Product';

interface ProductInfoProps {
  products: Product;
  quantity: number;
  selectedColor: SelectGrade;
  selectedSize: string;
  addCart: () => void;
  quantityChange: (increment: boolean) => void;
  user: Client;
  shippingCost: string | null;
  handleColorSelect: (color: string, image: string, id: string) => void;
  handleSizeSelect: (size: string) => void;
}

export const ProductInfo: React.FC<ProductInfoProps> = ({
  products,
  quantity,
  selectedColor,
  selectedSize,
  addCart,
  quantityChange,
  user,
  shippingCost,
  handleColorSelect,
  handleSizeSelect,
}) => {
  // const [selectedGrade, setSelectedGrade] = useState<string>('');

  const calculateDiscountPercentage = (
    originalPrice: number,
    discountedPrice: number
  ): string => {
    const original = originalPrice;
    const discount = discountedPrice;

    if (original > 0) {
      return ((1 - discount / original) * 100).toFixed(0);
    }
    return '0';
  };

  const installmentValue = (price: number): string => {
    const installmentAmount = (price / 12).toFixed(2);
    return `ou R$ ${price.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} em 12x de R$ ${parseFloat(installmentAmount).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
  };

  // const handleSelectGrade = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedGrade(e.target.value);
  // };

  // const limitText = (text: string, maxLength: number) => {
  //   return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  // };

  // const gradeOptions =
  //   (products.grade &&
  //     products.grade.map((grade) => ({
  //       value: grade.value,
  //       label: limitText(
  //         `Tamanho: ${grade.atributo} Detalhes: ${grade.value} (${grade.quantity} disponíveis)`,
  //         50
  //       ),
  //       title: `Tamanho: ${grade.atributo} | Detalhes: ${grade.value} | (${grade.quantity} disponíveis)`,
  //     }))) ||
  //   [];
  // const [selectedColor, setSelectedColor] = useState<string | null>(null);

  return (
    <div className="sm:p-4 rounded-lg w-full mx-auto bg-white">
      {/* Mais Vendido */}
      {/* <div className="flex items-center gap-2 mb-2">
        <span className="bg-orange-500 text-white text-xs font-bold py-1 px-2 rounded-full">
          MAIS VENDIDO
        </span>
        <span className="text-blue-500 text-xs">8º em Notebooks</span>
      </div> */}

      <div className="flex justify-between">
        <h1 className="text-xl font-bold mb-1">{products.name}</h1>
        <span className="cursor-pointer text-gray-400 text-sm">Denunciar</span>
      </div>
      <div className="flex items-center gap-1 text-sm mb-2">
        {products.ratings && products.ratings.length > 0 ? (
          <>
            <div className="flex items-center">
              <RatingStars2 rating={products.ratings[0].count} />
            </div>
            <span>({products.ratings[0].count})</span>
          </>
        ) : (
          <>
            <div className="flex items-center">
              <RatingStars2 rating={0} />
            </div>
            <span>({0})</span>
          </>
        )}
      </div>
      {products.promotion !== false &&
      products.grade[0].gradeSizes &&
      products.grade[0].gradeSizes[0].price.discount &&
      (products.grade[0].gradeSizes[0].price.discount !== null) !== null ? (
        <>
          <div className="flex items-center text-xl font-bold mb-2">
            <span>
              {toCurrency(
                parseFloat(
                  products.grade[0].gradeSizes[0].price.discount.toString() ||
                    '0'
                )
              )}
            </span>
            <span className="text-gray-500 text-sm line-through font-normal ml-2">{`${toCurrency(products.grade[0].gradeSizes[0].price.price)}`}</span>
            <span className="text-green-500 text-base ml-2">{`${calculateDiscountPercentage(
              products.grade[0].gradeSizes[0].price.price,
              products.grade[0].gradeSizes[0].price.discount
            )}% OFF no PIX`}</span>
          </div>
        </>
      ) : (
        <div className="flex items-center text-xl font-bold mb-2">
          {`${toCurrency(products.grade[0].gradeSizes[0].price.price)}`}
        </div>
      )}

      <div className="text-sm mb-2">
        {installmentValue(products.grade[0].gradeSizes[0].price.price)}
      </div>
      <div className="bg-blue-100 text-blue-500 text-xs p-2 w-fit rounded-md">
        10% OFF no Debito
      </div>
      <div className="flex mt-6">
        <div className="flex">
          {user.address?.map((user) => (
            <div className="flex gap-10" key={user.id}>
              <span>Frete</span>
              <div className="flex gap-3">
                <p>Frete para</p>
                <div className="flex flex-col">
                  <span className="font-bold">
                    {user.city}, {user.state}
                  </span>
                  {user.city === products.seller.city ? (
                    <span className="text-sm text-green-500 font-medium">
                      {!products.freeShipping ? 'Frete Local' : 'Frete Grátis'}
                    </span>
                  ) : (
                    <span className="text-sm">{shippingCost}</span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center py-5 gap-2 sm:gap-4">
        <span className="text-md font-medium text-gray-700">Quantidade:</span>
        <div className="flex items-center border rounded-md shadow-sm">
          <button
            className="w-7 h-7 flex items-center justify-center bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors rounded-l-md"
            onClick={() => quantityChange(false)}
          >
            −
          </button>
          <span className="px-4 text-lg font-semibold text-gray-800">
            {quantity}
          </span>
          <button
            className="w-7 h-7 flex items-center justify-center bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors rounded-r-md"
            onClick={() => quantityChange(true)}
          >
            +
          </button>
        </div>
        <p className="text-[0.8rem] sm:text-sm opacity-70">
          {products.stock} unidades disponíveis
        </p>
      </div>

      {/* Grade para poder selecionar categorias de produtos */}
      {products.grade?.length > 0 && (
        <div className="mt-4">
          <div className="flex flex-col space-y-4">
            <div>
              <h3 className="text-lg font-semibold mb-2">
                Selecione uma Cor:{' '}
                {selectedColor && (
                  <span className="hidden text-[#F14A3E] font-medium">
                    {selectedColor.color}
                  </span>
                )}
              </h3>
              <div className="flex gap-4">
                {products.grade.map((grade) => (
                  <button
                    key={grade.id}
                    onClick={() =>
                      handleColorSelect(
                        grade.color || '',
                        `https://sandbox.buunpsgpsystem.com.br/uploads/${grade.image}`,
                        grade.id ? grade.id : ''
                      )
                    }
                    className={`w-16 h-16 border rounded-md ${
                      selectedColor.color === grade.color
                        ? 'border-[#F14A3E]'
                        : 'border-[#f3cfcf]'
                    }`}
                    style={{
                      backgroundImage: `url(https://sandbox.buunpsgpsystem.com.br/uploads/${grade.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    title={grade.color || ''}
                  >
                    {!grade.image && <span>{grade.color || 'N/A'}</span>}
                  </button>
                ))}
              </div>
            </div>
            {/* Seleção de tamanhos */}
            {selectedColor && (
              <div>
                <h3 className="text-lg font-semibold mb-2">
                  Selecione um Tamanho:
                </h3>
                <div className="flex gap-4">
                  {products.grade
                    .find((grade) => grade.color === selectedColor.color)
                    ?.gradeSizes.map((size) => (
                      <button
                        key={size.id}
                        onClick={() => handleSizeSelect(size.size)}
                        className={`px-4 py-2 border rounded-md ${
                          selectedSize === size.size
                            ? 'border-[#F14A3E]'
                            : 'border-[#f3cfcf]'
                        }`}
                        title={`Size: ${size.size} | Stock: ${size.stock}`}
                      >
                        {size.size}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* end  */}
      <div className="flex flex-col sm:flex-row gap-2 sm:mt-5">
        <button
          type="button"
          onClick={() => addCart()}
          className="py-2 bg-[#fff1f1] text-[#F14A3E] hover:bg-[#f3cfcf] w-full border rounded-lg border-red-400 shadow-lg transition-all duration-300 flex gap-2 items-center justify-center"
        >
          <ShoppingCartIcon size={18} />
          Adicionar ao Carrinho
        </button>
        <button
          type="button"
          className="py-2 w-full sm:w-[50%] bg-[#F14A3E] hover:bg-red-600 text-white border rounded-lg border-red-400 shadow-lg transition-all duration-300"
        >
          Comprar
        </button>
      </div>
    </div>
  );
};
