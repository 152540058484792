import React from 'react';

// Interface para as notificações
interface Notification {
  id: number;
  type: string;
  message: string;
  orderId?: string;
  date: string;
  time: string;
  detailsUrl?: string;
  actionLabel?: string;
}

interface NotificationUIProps {
  notifications: Notification[];
}

const NotificationUI: React.FC<NotificationUIProps> = ({ notifications }) => {
  return (
    <div className="w-full">
      {/* Lista de notificações */}
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="border-b border-red-200  bg-white p-3"
        >
          <div className="flex justify-between items-center mb-2 flex-wrap">
            <h3 className="font-semibold text-md ">
              {/* Tamanho do texto ajustado */}
              {notification.type}
            </h3>
            <p className="text-xs text-gray-500 ">
              {notification.date} {notification.time}
            </p>
          </div>
          <p className="text-gray-600 text-sm">{notification.message}</p>
          {/* Renderiza o pedido somente se orderId existir */}
          {notification.orderId && (
            <p className="text-gray-600 text-sm">
              Pedido:{' '}
              <span className="text-black text-sm">{notification.orderId}</span>
            </p>
          )}
          <div className="flex justify-end mt-2">
            {notification.detailsUrl && (
              <a
                href={notification.detailsUrl}
                className="text-blue-500 text-sm mr-4"
              >
                {notification.actionLabel}
              </a>
            )}
            {notification.actionLabel && !notification.detailsUrl && (
              <button className="bg-red-500 text-white px-2 py-1 text-xs rounded">
                {notification.actionLabel}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationUI;
