import React from 'react';
import { Product } from 'src/model/Product';
import { StoreProfileUI } from './StoreProfile/ui';

interface StoreProfileUIProps {
  valuePrice: (product: Product, discount: boolean) => string;
}
export const StoreProfileScreen: React.FC<StoreProfileUIProps> = ({
  valuePrice,
}): JSX.Element => {
  return (
    <StoreProfileUI
      storeName={''}
      productCount={0}
      products={[]}
      valuePrice={valuePrice}
    />
  );
};
