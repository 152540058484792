import DOMPurify from 'dompurify';
import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { AdvertisingPolicies } from 'src/model/AdvertisingPolicies';
import { ReactComponent as Danger } from '../../../../../assets/icons/danger.svg';
import { Link } from 'react-router-dom';

interface AdvertisingUIProps {
  advertising?: AdvertisingPolicies;
}

export const AdvertisingUI: React.FC<AdvertisingUIProps> = ({
  advertising,
}): JSX.Element => {
  const sanitizedDescription = DOMPurify.sanitize(
    advertising ? advertising.description : 'carregando'
  );
  return (
    <AuthWrapper>
      <>
        <Link
          to="/create-account"
          className="text-xl mt-5 bg-[#F14A3E] rounded-lg px-4 py-1 shadow-lg hover:bg-red-600 transition-colors text-white"
        >
          Voltar
        </Link>
        <div className="flex flex-col items-center justify-center w-full container m-4 space-y-8 bg-white p-4 rounded-lg shadow-sm">
          <div className="flex flex-col items-center justify-center gap-3">
            <Danger className=" text-red-500 bg-[#ff689881] w-14 h-auto p-3 rounded-full pulse " />
            <h2 className="font-bold text-3xl">Politicas de Anuncio</h2>
          </div>
          <div
            className="space-y-3"
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          ></div>

          {/* 
        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            INTRODUÇÃO
          </h2>
          <p>
            Sigas todas as orientações abaixo para que seus anúncios não sejam
            impedidos de rodar na plataforma.
          </p>
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            CLAREZA
          </h2>
          <p>
            Seja claro ao apresentar um produto ou serviço, para que seu cliente
            não tenha a menor dúvida.
          </p>
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            ANÚNCIO ENGANOSO
          </h2>
          <p>
            Não faça nenhuma afirmação exagerada sobre o resultado de um serviço
            ou produto.
          </p>
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            TIPO DE ANUNCIO
          </h2>
          <p>
            É expressamente proibido os seguintes itens ou categorias: Produtos
            e serviços sexuais. Drogas ilícitas, armas ou qualquer item ou
            serviço que incite crimes. Anunciar medicamentos fora das normas da
            Anvisa. (Exemplo: É proibido pela Anvisa anunciar medicamentos de
            tarja preta ou vermelha para público comum. Esse tipo de medicamento
            só é permitido anunciar para profissionais capacitados da área
            médica. ) ( www.gov.br/anvisa/pt-br) Mencionar ou sugerir assuntos
            de natureza política, religiosa, social ou qualquer item, serviço ou
            algo que possa ser de opinião pessoal ou que possa levantar debates.
            as orientações abaixo para que seus anúncios não sejam impedidos de
            rodar na plataforma.
          </p>
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            CRIATIVOS PARA ANÚNCIOS
          </h2>
          <div className="flex items-center flex-wrap justify-center gap-2 ">
            <span className="span-destaque">Não use imagens sexuais</span>
            <span className="span-destaque">
              Nudez Insinuação sexual/Pose provocativa
            </span>
            <span className="span-destaque">
              Mostrar excessivamente a pele ou decote
            </span>
            <span className="span-destaque">Pose sexualmente sugestiva.</span>
          </div>

          <h2 className="font-normal text-2xl text-slate-400 text-center">
            VEJA OS EXEMPLOS ABAIXO:
          </h2>

          <img src={Provocativo} alt="" />
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            OBSERVE
          </h2>
          <p>
            O objetivo do anúncio será sempre chamar a atenção de um possível
            cliente no que diz respeito ao produto em questão, portanto é
            extremamente importante observar o que a imagem mais enfatiza. Na
            imagem (1) e imagem (2), as pessoas estão mostrando bastante a pele.
            Entretanto, mesmo nesse sentido a imagem (2) seria aprovada, pois
            está bem claro o objetivo dela. No entanto, mesmo mostrando a pele,
            a pose sugere que o objetivo é mostrar o modelo da roupa. Já a
            imagem (1) sugere sensualidade. A imagem (3) e (5) também sugerem
            por suas expressões faciais e corporais, que o objetivo é puramente
            sensual e pouco sugere o modelo de suas roupas. As imagens (4) e (6)
            mesmo mostrando a pele sugerem apenas a apresentação de suas roupas.
            ESSE DEVE SER O OBJETIVO.
          </p>
          <div className="flex flex-wrap items-center justify-center gap-2 ">
            <span className="span-destaque">Não use imagens chocantes</span>
            <span className="span-destaque">Acidentes</span>
            <span className="span-destaque">Sangue/violência excessiva</span>
            <span className="span-destaque">
              (Apontar armas para o usuário)
            </span>
          </div>
          <img src={Observe} alt="" />
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            Não use funcionalidades mentirosas.
          </h2>
          <p>
            Exemplo: Em uma determinada foto, jogo filme ou até brinquedo,
            mostra que o produto tenha uma função que não exista ou que talvez
            exista por pouco tempo ou até mesmo que isso só está disponível para
            determinados usuários. Neste caso deve haver informações claras aos
            usuarios. Caso contrario, será considerado anúncio enganoso.
          </p>
          <img src={Enganoso} alt="" />
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            TEXTO DO ANÚNCIO
            <br /> Não faça afirmações enganosas
          </h2>
          <p>
            Os anúncios não devem ter afirmações enganosas, fraude ou ilusórias.
            Os anúncios jamais poderão oferecer acesso total a conteúdos que
            está disponível apenas por assinatura. Não se deve fazer alegações
            de resultados irrealistas.
          </p>
          <img src={TextoEnganoso} alt="" />
        </div>

        <div className="space-y-3">
          <h2 className="font-normal text-2xl text-slate-400 text-center">
            TEXTO DO ANÚNCIO
          </h2>
          <p>
            As páginas de destino devem refletir com clareza o produto ou
            serviços anunciados. A URL deve funcionar em qualquer navegador. Não
            use URL duvidosas, que levem os usuários para locais fora de padrão
            (páginas não seguras). Ao invés disso, leve seus usuários a sites
            que passem segurança. Os anúncios não devem direcionar para uma
            página que contém downloads iniciados automaticamente, ou que
            contenha algum tipo de vírus. Também não se deve direcionar a um
            arquivo que requeira que o usuário obtenha outro aplicativo para
            abrir o conteúdo. Antes de enviar o anuncio para a análise,
            verifique se sua página de destino está em bom funcionamento. Se
            você está anunciando um aplicativo, saiba que ele terá que ser
            baixado pela plataforma “buunpo” a fim de ser analisado quanto a
            possíveis violações da política de anuncios.
          </p>
        </div> */}
        </div>
      </>
    </AuthWrapper>
  );
};
