// components/HomePrimaryBannerManagerUI.tsx
import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { CarouselBannerUI } from 'src/components/CarouselBanner';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { FormInputBannerHome } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';
import { Banners } from 'src/model/Banners';
import { NameFiles } from '..';

interface HomePrimaryBannerManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFilesBannerHome?: NameFiles;
  banners: Banners[];
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  //   onEditBanner: (id: string) => void;
  onRemoveBanner: (id: string) => void;
}

export const HomePrimaryBannerManagerUI: React.FC<
  HomePrimaryBannerManagerUIProps
> = ({
  banners,
  formData,
  formNameFilesBannerHome,
  formErrors,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
  //   onEditBanner,
  onRemoveBanner,
}) => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);
  const imageMobileBase64Ref = React.useRef<HTMLInputElement>(null);

  // const imageTypes = ['image', 'imageMobile'] as const;

  return (
    <div className="mb-4 space-y-2 grid grid-cols-1 ">
      <form onSubmit={onSubmitRegister} className="space-y-4 mt-5">
        <div className="flex flex-wrap gap-2 lg:gap-10">
          <div>
            <InputFile
              refInput={imageMobileBase64Ref}
              label="Upload da Imagem (Mobile)"
              name="imageBannerHomeMobile"
              fileName={formNameFilesBannerHome?.imageBannerHomeMobile}
              onChange={(e) =>
                onChangeFormFileInput(
                  FormInputBannerHome.imageBannerHomeMobile
                )(e.target.files?.[0])
              }
              error={
                formErrors.imageBannerHomeMobile &&
                formErrors.imageBannerHomeMobile[0]
              }
              wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
            />
            <span className="text-sm text-gray-500">
              (A imagem mobile deve ter tamanho de 1920x1080)
            </span>
          </div>
          <div>
            <InputFile
              refInput={imageBase64Ref}
              name="imageBannerHome"
              label="Upload da Imagem (Desktop)"
              fileName={formNameFilesBannerHome?.imageBannerHome}
              onChange={(e) =>
                onChangeFormFileInput(FormInputBannerHome.imageBannerHome)(
                  e.target.files?.[0]
                )
              }
              error={
                formErrors.imageBannerHome && formErrors.imageBannerHome[0]
              }
              wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
            />
            <span className="text-sm text-gray-500">
              (A imagem desktop deve ter tamanho de 1920x720)
            </span>
          </div>
        </div>
        <InputText
          name="altTextBannerHome"
          label="Texto Alternativo das imagens"
          placeholder="Digite o texto alternativo..."
          value={formData[FormInputBannerHome.altTextBannerHome]}
          onChange={(e) =>
            onChangeFormInput(FormInputBannerHome.altTextBannerHome)(
              e.target.value
            )
          }
          error={
            formErrors.altTextBannerHome && formErrors.altTextBannerHome[0]
          }
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputText
          name="linkBannerHome"
          label="Link"
          placeholder="Digite o link..."
          value={formData[FormInputBannerHome.linkBannerHome]}
          onChange={(e) =>
            onChangeFormInput(FormInputBannerHome.linkBannerHome)(
              e.target.value
            )
          }
          error={formErrors.linkBannerHome && formErrors.linkBannerHome[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <Button
          type="submit"
          title="Salvar Banner"
          buttonStyle="red"
          size="lg"
        />
      </form>

      {/* Exibição dos banners inseridos */}
      <div className="overflow-x-auto pb-4">
        <div className="grid gap-4">
          {banners &&
            banners.map((banner, index) => (
              <div
                key={`${banner.id}-${index}`}
                className="relative border p-4 rounded-lg shadow-md"
              >
                {/* Imagem do banner principal */}
                <img
                  src={banner.image}
                  alt={banner.altText}
                  className="w-full h-32 object-contain rounded mb-2"
                />
                <p className="text-center text-sm">{banner.altText}</p>

                {/* Imagem do banner mobile (se disponível) */}
                {banner.imageMobile && (
                  <>
                    <img
                      src={banner.imageMobile}
                      alt={`${banner.altText} - Mobile`}
                      className="w-full h-32 object-contain rounded mt-2 mb-2"
                    />
                    <p className="text-center text-sm">(VersãoMobile)</p>
                  </>
                )}

                {/* Botão para excluir o banner atual */}
                <button
                  onClick={() => onRemoveBanner(banner.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition mt-4"
                >
                  Excluir Banner
                </button>
              </div>
            ))}
        </div>
      </div>

      {/* Componente Carousel exibindo os banners */}
      <div className="w-full ">
        {CarouselBannerUI && (
          <CarouselBannerUI
            slides={banners}
            hideIndicators={false}
            hideControls={false}
            className="aspect-[4/2] md:aspect-[10/3]"
          />
        )}
      </div>
    </div>
  );
};
