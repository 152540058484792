import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  value: string;
  type?:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'tel'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'hidden';
  label?: string;
  error?: string | null | undefined;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  maxLength?: number;
  renderForward?: React.ReactNode;
  id?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputText: React.FC<InputProps> = ({
  name,
  type = 'text',
  value,
  placeholder,
  error,
  label,
  maxLength,
  className = '',
  labelClassName = '',
  inputClassName = '',
  renderForward,
  disabled,
  id,
  onBlur,
  onChange,
}) => (
  <div className={`relative  space-y-1.5 ${className}`}>
    {label && (
      <label
        htmlFor={name}
        className={`block text-gray-500 text-base font-dmsans font-regular ${labelClassName}`}
      >
        {label}
      </label>
    )}
    <input
      id={id}
      aria-invalid={error ? 'true' : 'false'}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder ?? 'Digite aqui'}
      maxLength={maxLength}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      className={`relative appearance-none rounded w-full
         text-input text-base leading-tight focus:outline-none ${
           error ? 'border-red-500' : 'border-none' // Remover borda padrão
         } ${inputClassName}`}
    />
    {renderForward}
    {error && <p className="text-red-500 text-xs">{error}</p>}
  </div>
);
