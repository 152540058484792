import React from 'react';
import { RadioInput } from 'src/components/InputRadio';
import PaymentType from 'src/model/PaymentType';

interface PaymentTypeProps {
  onSelectType: (type: PaymentType) => void;
}

export const PaymentTypeUI: React.FC<PaymentTypeProps> = ({ onSelectType }) => {
  return (
    <div className="flex justify-center gap-4">
      <div className="border border-gray-300 rounded-md p-4 shadow-sm">
        <RadioInput
          name="paymentType"
          label="Cartão de crédito"
          value="CARD"
          onChange={() => onSelectType(PaymentType.CREDIT_CARD)}
        />
      </div>
      <div className="border border-gray-300 rounded-md p-4 shadow-sm">
        <RadioInput
          name="paymentType"
          label="PIX"
          value="PIX"
          onChange={() => onSelectType(PaymentType.PIX)}
        />
      </div>
    </div>
  );
};
