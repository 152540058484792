import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NameFiles } from 'src/features/createAccount/screens/CreateAccount/ui';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Brand } from 'src/model/Brand';
import { Category } from 'src/model/Category';
import { Dimensions } from 'src/model/Dimensions';
import { Grade } from 'src/model/Grade';
import { GradeSize } from 'src/model/GradeSize';
import { ProductStatus } from 'src/model/Product';
import { Warranty } from 'src/model/Warranty';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import {
  FormInputGrade,
  FormInputGradeSize,
  FormRegisterProduct,
  ProductBasic,
} from '../../types/types';
import { CreateProductUI } from './ui';

// Define um tipo seguro para erros de Axios
interface AxiosError {
  response?: {
    data?: string | Record<string, unknown>;
  };
}

export const CreateProductScreen = () => {
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  // const [formNameFilesGrade, setFormNameFilesGrade] = useState<NameFiles>({});
  const [files, setFiles] = useState<File[]>([]);
  const [filesGrade, setFilesGrade] = useState<File[]>([]);
  const [filesBase64, setFilesBase64] = useState<string[]>([]);
  const [filesBase64Grade, setFilesBase64Grade] = useState<string>('');
  const [previewFiles, setPreviewFiles] = useState<string[]>([]);
  const [previewFilesGrade, setPreviewFilesGrade] = useState<string>('');
  const [previewGradesSizes, setPreviewGradesSizes] = useState<GradeSize[]>([]);
  //   const [selectTags, setSelectTags] = useState<string[]>([]);
  const [inputTags, setInputTags] = useState<string[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [grade, setGrade] = useState<Grade[]>([]);
  const [gradeSize, setGradeSize] = useState<GradeSize[]>([]);
  const [shippingCost, setShippingCost] = useState<string | null>(null);
  const [isGrade, setIsGrade] = useState<boolean>(false);

  const getCategory = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await api.get<Category[]>('/category/find-all');

      if (response.data) {
        setCategory(response.data);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      // resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      const totalFiles = [...files, ...newFiles];
      const images = totalFiles.filter(
        (file) => file.type.startsWith('image/') && totalFiles.length <= 4
      );
      const videos = totalFiles.filter((file) =>
        file.type.startsWith('video/')
      );

      if (images.length > 4 || videos.length > 1 || totalFiles.length > 5) {
        alert('Máximo de 4 imagens e 1 vídeo ou 5 arquivos no total.');
        return;
      }

      setFiles(totalFiles.slice(0, 5));

      totalFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFilesBase64((prevBase64) => [...prevBase64, base64]);
          }
        };
      });

      const previews = totalFiles.map((file) => URL.createObjectURL(file));
      setPreviewFiles(previews);
    }
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    const updatedPreviews = previewFiles.filter((_, i) => i !== index);
    setPreviewFiles(updatedPreviews);
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    // resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      name: '',
      description: '',
      priceDiscount: '',
      sku: '',
      status: '',
      promotion: '',
      brandName: '',
      dimensionsWidth: '',
      dimensionsHeight: '',
      dimensionsDepth: '',
      dimensionsWeight: '',
      warranty: '',
      parcel: '',
      tag: '',
      freeShipping: '',
      hiddenField: '',
      nameGrade: '',
      size: '',
      stock: '',
      price: '',
      discount: '',
      color: '',
    },
    validators: {
      name: [validators.required],
      description: [validators.required],
      status: [validators.required],
      brandName: [validators.required],
      dimensionsWidth: [validators.required],
      dimensionsHeight: [validators.required],
      dimensionsDepth: [validators.required],
      dimensionsWeight: [validators.required],
      warranty: [validators.required],
    },
  });

  const handleOnChangeFileInput =
    (inputName: string) => (file: File | undefined) => {
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputRegister(inputName)(base64);
          }
        };
      } else {
        setErrorRegister({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  //Aqui
  const handleOnChangeFileInputGradeSize = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (!file.type.startsWith('image/')) {
        alert('Apenas arquivos de imagem são permitidos.');
        return;
      }

      setFilesGrade([file]);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result?.toString();
        if (base64) {
          setFilesBase64Grade(base64);
        }
      };
      const previews = URL.createObjectURL(file);
      setPreviewFilesGrade(previews);
    }
  };

  const handleStatusType = (value: string): ProductStatus => {
    switch (value) {
      case 'Ativo':
        return ProductStatus.ACTIVE;
      case 'Inativo':
        return ProductStatus.INACTIVE;
      case 'Estoque Zerado':
        return ProductStatus.DISCONTINUED;
      default:
        return ProductStatus.INACTIVE;
    }
  };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValidRegister()) {
      try {
        dispatch(setLoading(true));
        const brand = {
          name: formDataRegister[FormRegisterProduct.brandName],
        } as Brand;
        const dimensions = {
          width: formDataRegister[FormRegisterProduct.dimensionsWidth],
          height: formDataRegister[FormRegisterProduct.dimensionsHeight],
          depth: formDataRegister[FormRegisterProduct.dimensionsDepth],
          weight: formDataRegister[FormRegisterProduct.dimensionsWeight],
        } as Dimensions;

        // const price = {
        //   price: formDataRegister[FormRegisterProduct.price],
        //   discount: formDataRegister[FormRegisterProduct.priceDiscount],
        // } as unknown as Price;

        const warranty = {
          warranty: formDataRegister[FormRegisterProduct.priceDiscount],
        } as Warranty;
        const gradeDefine: Grade[] = [];
        if (isGrade) {
          grade.forEach((item) => {
            gradeDefine.push({
              name: item.name,
              color: item.color,
              image: item.image,
              gradeSizes: item.gradeSizes,
            });
          });
          if (grade.length <= 0) {
            toast.error('Adicione ao menos uma variação.');
            setErrorRegister({
              hiddenField: ['Adicione ao menos uma variação.'],
            });
            return;
          }
        } else {
          gradeDefine.push({
            name: 'UNICO',
            color: 'UNICO',
            image: '',
            gradeSizes: [
              {
                size: 'unico',
                stock: formDataRegister[FormInputGradeSize.stock],
                price: {
                  price: parseInt(formDataRegister[FormInputGradeSize.price]),
                  discount: parseInt(
                    formDataRegister[FormInputGradeSize.discount]
                  ),
                },
              },
            ],
          });
        }
        const payload: ProductBasic = {
          name: formDataRegister[FormRegisterProduct.name],
          description: formDataRegister[FormRegisterProduct.description],
          stock: formDataRegister[FormRegisterProduct.stock],
          sku: formDataRegister[FormRegisterProduct.sku],
          promotion:
            formDataRegister[FormRegisterProduct.promotion] === '0'
              ? false
              : true,
          status: handleStatusType(
            formDataRegister[FormRegisterProduct.status]
          ),
          category: selectedCategories,
          brand: brand,
          dimensions: dimensions,
          imagens: filesBase64,
          // price: price,
          tags: inputTags,
          warranty: warranty,
          grade: gradeDefine,
          freeShipping:
            formDataRegister[FormRegisterProduct.freeShipping] === 'true',
        };

        console.log(payload);
        const response = await api.post('/product', payload);

        if (response.status === 201) {
          // resetFormRegister();

          // Reset dos estados adicionais
          setSelectedCategories([]);
          setGrade([]);
          setInputTags([]);
          setPreviewFiles([]);
          setFiles([]);
          setFilesBase64([]);
          setFormNameFiles({});
          toast.success('Produto cadastrado com sucesso!');

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error('Falha ao cadastrar o produto');
        }
        // eslint-disable-next-line
        // if (35 > 50) {
        //   resetFormRegister();
        // }
      } catch (error: unknown) {
        dispatch(setLoading(false));
        let errorMessage =
          'Falha ao realizar o cadastro, tente novamente mais tarde';

        if (error instanceof Error && (error as AxiosError).response) {
          const responseData = (error as AxiosError).response?.data;
          errorMessage =
            typeof responseData === 'string'
              ? responseData
              : JSON.stringify(responseData);
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        toast.error(errorMessage);
        setErrorRegister({
          document: [errorMessage],
        });
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      toast.error('Preencha todos os campos obrigatórios corretamente.');
    }
  };

  // Função para adicionar uma tag
  const handleAddTag = (tag: string) => {
    if (tag && !inputTags.includes(tag)) {
      setInputTags([...inputTags, tag]);
    } else {
      toast.error('Tag já adicionada ou inválida.');
    }
  };

  // Função para remover uma tag
  const handleRemoveTag = (tagToRemove: string) => {
    setInputTags(inputTags.filter((tag) => tag !== tagToRemove));
  };

  //   const handleTagSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     const selectedTag = e.target.value;
  //     if (selectedTag && !selectTags.includes(selectedTag)) {
  //       setSelectTags([...selectTags, selectedTag]);
  //     }
  //   };

  //   const handleRemoveTag = (tagToRemove: string) => {
  //     setSelectTags(selectTags.filter((tag) => tag !== tagToRemove));
  //   };

  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    if (selectedId) {
      const selectedCategory = category.find((cat) => cat.id === selectedId);
      if (
        selectedCategory &&
        !selectedCategories.some((cat) => cat.id === selectedId)
      ) {
        setSelectedCategories([...selectedCategories, selectedCategory]);
      }
    }
  };

  const handleRemoveCategory = (id: string) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat.id !== id));
  };

  const handleAddQuantiValue = (e: FormEvent) => {
    e.preventDefault();
    const newGrade: Grade = {
      color: formDataRegister[FormInputGrade.color] || '',
      name: formDataRegister[FormInputGrade.nameGrade] || '',
      image: filesBase64Grade || '',
      gradeSizes: gradeSize?.length ? gradeSize : [],
    };
    console.log(newGrade);
    const isDuplicate = grade.some((item) => {
      const isSameColor =
        item.color?.toLowerCase() === newGrade.color?.toLowerCase();
      const isSameName =
        item.name && item.name.toLowerCase() === newGrade.name?.toLowerCase();
      setFilesBase64Grade('');
      return isSameColor || isSameName;
    });

    if (isDuplicate) {
      toast.error('Já existe uma variação com esta cor ou nome.');
      return;
    }

    if (!newGrade) {
      toast.error('Por favor, preencha a grade.');
      return;
    }

    if (newGrade.color === '') {
      if (newGrade.name === '') {
        toast.error('Por favor, preencha a grade com nome');
        return;
      }
    }

    if (newGrade && gradeSize.length > 0) {
      setGrade([...grade, newGrade]);
      onChangeFormInputRegister(FormInputGrade.color)('');
      onChangeFormInputRegister(FormInputGrade.nameGrade)('');
      onChangeFormInputRegister(FormInputGrade.imageBase64Grade)('');
      setGradeSize([]);
      setPreviewGradesSizes([]);
      setPreviewFilesGrade('');
    }
  };

  const handleAddGradeSize = (e: FormEvent) => {
    e.preventDefault();
    const newGradeSize: GradeSize = {
      size: formDataRegister[FormInputGradeSize.size],
      stock: formDataRegister[FormInputGradeSize.stock],
      price: {
        price: parseInt(formDataRegister[FormInputGradeSize.price]),
        discount: parseInt(formDataRegister[FormInputGradeSize.discount]),
      },
    };
    console.log(newGradeSize);
    if (newGradeSize && !inputTags.includes(newGradeSize.size.toString())) {
      setGradeSize([...gradeSize, newGradeSize]);
      onChangeFormInputRegister(FormInputGradeSize.size)('');
      onChangeFormInputRegister(FormInputGradeSize.stock)('');
      onChangeFormInputRegister(FormInputGradeSize.price)('');
      onChangeFormInputRegister(FormInputGradeSize.discount)('');
    }

    setPreviewGradesSizes([...previewGradesSizes, newGradeSize]);
  };

  const handleShippingCalc = async (
    altura: string,
    largura: string,
    profundidade: string,
    peso: string
  ) => {
    try {
      const payload = { altura, largura, profundidade, peso };

      const response = await api.post('/product/calc-frete', payload);

      if (response.data.frete && response.data.frete !== 0) {
        setShippingCost(toCurrency(response.data.frete));
      } else if (
        altura === '' ||
        largura === '' ||
        profundidade === '' ||
        peso === ''
      ) {
        setShippingCost('Preencha todos os campos.');
      } else {
        setShippingCost('Valor de frete indisponível ou inválido.');
      }
    } catch (error) {
      console.error('Erro ao calcular frete:', error);
      setShippingCost('Erro ao calcular o frete.');
    }
  };

  const handleRemoveGrade = (index: number): void => {
    setGrade(grade.filter((_, i) => i !== index));
  };

  const handleIsGrade = (inputName: string) => (value: string) => {
    console.log(inputName, value);
    setIsGrade(value === 'true');
  };

  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, []);

  return (
    <CreateProductUI
      state={loading}
      formData={formDataRegister}
      formErrors={formErrorRegister}
      formNameFiles={formNameFiles}
      onChangeFormInput={onChangeFormInputRegister}
      onSubmitRegister={handleOnSubmitRegister}
      onChangeFormFileInput={handleOnChangeFileInput}
      filesImage={files}
      categoryOptions={category}
      onDeleteFile={handleDeleteFile}
      previewFiles={previewFiles}
      previewFilesGrade={previewFilesGrade}
      previewGradesSizes={previewGradesSizes}
      selectedCategories={selectedCategories}
      FileUpload={handleFileUpload}
      inputTags={inputTags}
      onAddTag={handleAddTag} // Passa a função correta
      onRemoveTag={handleRemoveTag}
      onSelectCategory={handleCategorySelect}
      onRemoveCategory={handleRemoveCategory}
      addQuantiValue={handleAddQuantiValue}
      removeGrade={handleRemoveGrade}
      isGrade={isGrade}
      grade={grade}
      shippingCost={shippingCost}
      onShippingCalc={handleShippingCalc}
      addSize={handleAddGradeSize}
      // formNameFilesGrade={formNameFilesGrade}
      // formGradeSize={formDataGradeSize}
      // formErrorsGradeSize={formErrorsGradeSize}
      // onChangeFormGradeSize={onChangeFormGradeSize}
      onChangeFormFileInputGradeSize={handleOnChangeFileInputGradeSize}
      filesImageGrade={filesGrade}
      onGrade={handleIsGrade}
    />
  );
};
