import React from 'react';
import { ProfileSideMenu } from 'src/components/ProfileSideMenu';
import { CategoriesManagerScreen } from 'src/features/admin/components/Categories';
import { UsersManagerScreen } from 'src/features/admin/components/UsersManager';
// import DiscountController from 'src/features/admin/components/DiscountController';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { HomeSecondaryBannerManagerScreen } from 'src/features/admin/components/HomeSecondaryBanner';
import { HomePrimaryBannerManagerScreen } from 'src/features/admin/components/HomePrimaryBanner';
import { HomeThirdBannerManagerScreen } from 'src/features/admin/components/HomeThirdBanner';
import AppQrCodeManagerScreen from 'src/features/admin/components/AppQrCode';
import { PlatformProfitControllerScreen } from 'src/features/admin/components/PlataformProfitController';
import VideoTutorialManagerScreen from 'src/features/admin/components/VideoTutorial';
import { SocialLinksManagerScreen } from 'src/features/admin/components/SocialLinks';
import { PrivacyManagerScreen } from 'src/features/admin/components/PrivacyPolicyManager';
import { AdvertisingPolicyManagerScreen } from 'src/features/admin/components/AdvertisingPolicyManager';
import Client from 'src/model/User';
import { CreateTagScreen } from 'src/features/admin/components/TagsManager';
import { HomeMidleBannerManagerScreen } from 'src/features/admin/components/MidleBanner';
import { DashboardScreen } from 'src/features/admin/components/Dashboard';
import { NotificationScreenAdm } from 'src/features/admin/components/NotificationAdm';
import { ProductApprovalScreen } from 'src/features/admin/components/ProductApproval';

interface AdminUIProps {
  user: Client;
}

export const AdminUI: React.FC<AdminUIProps> = ({ user }): JSX.Element => {
  const menuItems = [
    {
      title: 'Dashboard',
      content: (
        <div className="w-full">
          <DashboardScreen />
        </div>
      ),
    },
    {
      title: 'Notificações',
      content: (
        <div className="w-full">
          <NotificationScreenAdm />
        </div>
      ),
    },
    {
      title: 'Gerenciar Usuarios',
      content: (
        <div className="w-full">
          <UsersManagerScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Produtos',
      content: (
        <div className="w-full">
          <ProductApprovalScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Ganhos',
      content: (
        <div className="w-full">
          <PlatformProfitControllerScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Tutoriais',
      content: (
        <div className="w-full">
          <VideoTutorialManagerScreen />
        </div>
      ),
    },
    // {
    //   title: 'Gerenciar Serviços',
    //   content: (
    //     <div className="w-full">
    //       <ServicesCardManager />
    //     </div>
    //   ),
    // },
    // {
    //   title: 'Cadastro de Imagens',
    //   content: (
    //     <div className="w-full">
    //       <ImageRegistration />
    //     </div>
    //   ),
    // },
    {
      title: 'Gerenciar Links Sociais',
      content: (
        <div className="w-full">
          <SocialLinksManagerScreen />
        </div>
      ),
    },

    {
      title: 'Gerenciar QrCode',
      content: (
        <div className="w-full h-screen">
          <AppQrCodeManagerScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Políticas de Privacidade',
      content: (
        <div className="w-full h-screen">
          <PrivacyManagerScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Políticas de Anúncios',
      content: (
        <div className="w-full h-screen">
          <AdvertisingPolicyManagerScreen />
        </div>
      ),
    },

    {
      title: 'Banners Principais',
      content: (
        <div className="w-full">
          <HomePrimaryBannerManagerScreen />
        </div>
      ),
    },
    {
      title: 'Banners Destaques',
      content: (
        <div className="w-full">
          <HomeSecondaryBannerManagerScreen />
        </div>
      ),
    },
    {
      title: 'Novidades e extras',
      content: (
        <div className="w-full">
          <HomeThirdBannerManagerScreen />
        </div>
      ),
    },
    {
      title: 'Banners do Meio',
      content: (
        <div className="w-full">
          <HomeMidleBannerManagerScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Categorias Home',
      content: (
        <div className="w-full">
          <CategoriesManagerScreen />
        </div>
      ),
    },
    {
      title: 'Gerenciar Tags',
      content: (
        <div className="w-full">
          <CreateTagScreen />
        </div>
      ),
    },
    // Adicione mais itens conforme necessário
  ];
  return (
    <AuthWrapper>
      {/* Sidebar (menu de navegação) */}
      <div className="container bg-white mt-4 rounded-md border border-red-200">
        <ProfileSideMenu user={user} menuItems={menuItems} />
      </div>
    </AuthWrapper>
  );
};
