import React, { useEffect, useState } from 'react';
import { Product } from 'src/model/Product';
import { Category } from 'src/model/Category';
import { SidebarWithProductsUI } from './ui';
import { api } from 'src/services/api';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { useDispatch } from 'react-redux';

interface SidebarProductScreenProps {
  products: Product[];
  valuePrice: (product: Product, discount: boolean) => string;
}

export const SidebarProductScreen: React.FC<SidebarProductScreenProps> = ({
  products,
  valuePrice,
}) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(
    'all'
  );
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);

  // Extrair categorias únicas dos produtos
  useEffect(() => {
    const allCategories = products
      .flatMap((product) => product.category)
      .filter(
        (category, index, self) =>
          self.findIndex((c) => c.id === category.id) === index
      );

    setCategories([{ id: 'all', name: 'Todos' }, ...allCategories]);
  }, [products]);

  // Filtrar produtos com base na categoria ativa
  useEffect(() => {
    if (activeCategoryId === 'all' || !activeCategoryId) {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.category.some((category) => category.id === activeCategoryId)
        )
      );
    }
  }, [activeCategoryId, products]);

  const handleCategorySelect = (categoryId: string | null) => {
    setActiveCategoryId(categoryId);
  };

  const getProduct = async (
    isFilterCategory: boolean,
    id?: string
  ): Promise<void> => {
    dispatch(setLoading(true));
    try {
      let urlProduct: string;
      if (isFilterCategory) {
        urlProduct = `/product/category/${id}`;
      } else {
        urlProduct = `product/all`;
      }
      const response = await api.get<Product[]>(urlProduct);

      if (response.status === 200) {
        setFilteredProducts(response.data);
        const categories: Category[] = [];
        response.data.forEach((product) => {
          if (product.category && product.category.length > 0) {
            product.category.forEach((category) => {
              if (!categories.find((c) => c.id === category.id)) {
                categories.push(category);
              }
            });
          }
        });
        setCategories(categories);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getProduct(true);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarWithProductsUI
      categories={{
        categories,
        activeCategoryId,
        onCategorySelect: handleCategorySelect,
      }}
      products={{ products: filteredProducts }}
      valuePrice={valuePrice}
    />
  );
};
