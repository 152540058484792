import React, { useState, useRef, useEffect } from 'react';

interface ChatMessage {
  sender: string;
  content: string;
}

interface Chat {
  id: string;
  name: string;
  lastMessage: string;
  timestamp: string;
  unread: number;
  messages: ChatMessage[];
}

interface ChatProps {
  chats: Chat[];
}

export const ChatComponent: React.FC<ChatProps> = ({ chats }) => {
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatData, setChatData] = useState(chats); // Usar um estado local para gerenciar o chat
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Efeito para rolar automaticamente para a última mensagem
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Quando um chat é selecionado, carregar as mensagens e zerar as mensagens não lidas
  const handleSelectChat = (chatId: string) => {
    const updatedChats = chatData.map((chat) =>
      chat.id === chatId ? { ...chat, unread: 0 } : chat
    );

    // Atualizar a lista de chats
    setChatData(updatedChats);

    // Carregar as mensagens do chat selecionado
    const selected = updatedChats.find((chat) => chat.id === chatId);
    if (selected) {
      setMessages(selected.messages);
      setSelectedChat(chatId);
    }
  };

  // Função para enviar uma nova mensagem
  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const newMessageObject = { sender: 'Você', content: newMessage };
    setMessages([...messages, newMessageObject]);
    setNewMessage(''); // Limpar a entrada de texto
  };

  return (
    <div className="flex w-full h-screen ">
      {/* Lista de Conversas */}
      <div className="w-1/3 border-r border-red-200 ">
        <div className="p-4">
          <input
            type="text"
            placeholder="Pesquisar"
            className="w-full p-2 relative appearance-none rounded
         text-input text-base leading-tight focus:outline-none border border-gray-300"
          />
        </div>
        <div className="overflow-y-auto">
          {chatData.map((chat) => (
            <div
              key={chat.id}
              onClick={() => handleSelectChat(chat.id)}
              className={`p-4 cursor-pointer ${
                selectedChat === chat.id ? 'bg-red-100' : ''
              } hover:bg-red-200`}
            >
              <div className="flex justify-between items-center">
                <h3 className="font-semibold">{chat.name}</h3>
                {chat.unread > 0 && (
                  <span className="text-xs bg-red-500 text-white px-2 py-1 rounded-full">
                    {chat.unread}
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-600 truncate">
                {chat.lastMessage}
              </p>
              <p className="text-xs text-gray-400">{chat.timestamp}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Conteúdo do Chat */}
      {selectedChat ? (
        <div className="w-2/3 flex flex-col">
          <div className="p-4 border-b border-red-200 ">
            <h2 className="font-semibold">
              Conversa com
              {chatData.find((chat) => chat.id === selectedChat)?.name}
            </h2>
          </div>
          <div className="flex-grow p-4 overflow-y-auto">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`mb-4 flex ${
                  msg.sender === 'Você' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`p-3 rounded-lg max-w-xs ${
                    msg.sender === 'Você'
                      ? 'bg-red-500 text-white'
                      : 'bg-red-300 text-black'
                  }`}
                >
                  <strong>{msg.sender}</strong>: {msg.content}
                </div>
              </div>
            ))}
            {/* Referência para rolar para a última mensagem */}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-4 border-t border-red-200 flex items-center">
            <input
              type="text"
              placeholder="Escreva sua mensagem"
              className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              className="ml-2 px-4 py-2 bg-red-500  hover:bg-red-600 text-white rounded-lg"
              onClick={handleSendMessage}
            >
              Enviar
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <h1 className="text-gray-500 text-lg">Selecione uma conversa</h1>
        </div>
      )}
    </div>
  );
};
