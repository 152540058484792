import React, { useEffect, useState } from 'react';
import { HomeUI } from './ui';
import { Product } from 'src/model/Product';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { Category } from 'src/model/Category';
import { Banners } from 'src/model/Banners';
import { useHistory } from 'react-router-dom';
import { toCurrency } from 'src/helpers/currency/toCurrency';

export const HomeScreen: React.FC = (): JSX.Element => {
  const [products, setProdutos] = useState<Product[]>([]);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<Category[]>([]);
  const [banners, setBanners] = useState<Banners[]>([]);
  const [bannerService, setBannerService] = useState<Banners[]>([]);
  const [bannerImages, setBannerImages] = useState<Banners[]>([]);
  const [bannerMidleImages, setBannerMidleImages] = useState<Banners[]>([]);
  const history = useHistory();

  const goCategory = (id: string) => {
    history.replace(`/products-by-category/${id}`);
  };
  const goSlide = (id: string) => {
    history.push(`/products-by-category/${id}`);
  };

  const getBannersHome = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME'
      );
      if (response.status === 200) {
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getBannersService = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/SERVICE_HOME'
      );
      if (response.status === 200) {
        setBannerService(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getBannerImages = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/SECUDARY_HOME'
      );
      if (response.status === 200) {
        setBannerImages(response.data);
      }
    } catch (erro) {
      console.error('Erro ao buscar banners:', erro);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getBannerMidleImages = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>('/banner/find-all/MIDLE_HOME');
      if (response.status === 200) {
        setBannerMidleImages(response.data);
      }
    } catch (erro) {
      console.error('Erro ao buscar banners:', erro);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getCategories = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Category[]>('/category/find-all');
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product[]>('/product/all');

      if (response.status === 200) {
        setProdutos(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleValuePrice = (product: Product, discount: boolean): string => {
    let price: string = '';

    if (product.grade) {
      outerLoop: for (const grade of product.grade) {
        if (grade.gradeSizes) {
          for (const gradeSize of grade.gradeSizes) {
            if (gradeSize.price) {
              if (discount) {
                // eslint-disable-next-line
                product.promotion
                  ? (price = toCurrency(gradeSize.price.discount))
                  : (price = toCurrency(gradeSize.price.price));
              } else {
                price = toCurrency(gradeSize.price.price);
              }
              break outerLoop;
            }
          }
        }
      }
    }
    return price;
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBannersHome();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBannersService();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getBannerImages();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getBannerMidleImages();
    // eslint-disable-next-line
  }, []);
  return (
    <HomeUI
      goCategory={goCategory}
      goSlide={goSlide}
      bannerImages={bannerImages}
      bannerMidleImages={bannerMidleImages}
      product={products}
      categories={categories}
      banners={banners}
      bannerService={bannerService}
      valuePrice={handleValuePrice}
    />
  );
};
