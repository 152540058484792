// screens/HomeMidleBannerManagerScreen.tsx
import React, { FormEvent, useEffect, useState } from 'react';
import { Banners } from 'src/model/Banners';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { useDispatch } from 'react-redux';
import { BannerBasic, FormInputBannerMidleBanner } from '../../types';
import { BannerType } from 'src/model/BannerType';
import { toast } from 'react-toastify';
import { HomeMidleBannerManagerUI } from './ui';

export interface NameFiles {
  [key: string]: string;
}

export const HomeMidleBannerManagerScreen: React.FC = () => {
  const [banners, setBanners] = useState<Banners[]>([]);
  const dispatch = useDispatch();
  //   const { loading } = useSelector(useLoading);
  const [formNameFilesMidle, setFormNameFilesMidle] = useState<{
    [key: string]: string;
  }>({});

  const getBanners = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>('/banner/find-all/MIDLE_HOME');
      if (response.status === 200) {
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataMidle,
    formErrors: formErrorsMidle,
    setErrors: setErrorsMidle,
    onChangeFormInput: onChangeFormInputMidle,
    isFormValid: isFormValidMidle,
    resetForm: resetFormMidle,
  } = useForm({
    initialData: {
      altTextMidleBanner: '',
      imageMidleBanner: '',
      linkMidleBanner: '',
    },
    validators: {
      altTextMidleBanner: [validators.required],
      imageMidleBanner: [validators.required],
      linkMidleBanner: [validators.required],
    },
  });

  const handleSubmitMidle = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidMidle()) {
        dispatch(setLoading(true));
        const payload: BannerBasic = {
          altText: formDataMidle[FormInputBannerMidleBanner.altTextMidleBanner],
          image: formDataMidle[FormInputBannerMidleBanner.imageMidleBanner],
          link: formDataMidle[FormInputBannerMidleBanner.linkMidleBanner],
          privatetype: BannerType.MIDLE_HOME,
        };

        const response = await api.post<Banners>('/banner', payload);
        if (response.status === 201 || response.status === 200) {
          getBanners();
          resetFormMidle();
          setFormNameFilesMidle({});
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorsMidle({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeFileInputSecurary =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png|webp)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFilesMidle({
              ...formNameFilesMidle,
              [inputName]: file.name,
            });
            onChangeFormInputMidle(inputName)('');
            onChangeFormInputMidle(inputName)(base64);
          }
        };
      } else {
        setErrorsMidle({
          [inputName]: ['O formato deve ser .jpg, .jpeg, .png ou .webp'],
        });
      }
    };

  //   const handleEditBanner = (id: string) => {
  //     console.log(id);
  //       const banner = banners.find((b) => b.id === id);
  //       if (banner) {
  //         setFormData({ image: banner.image, altText: banner.altText });
  //         setFormNameFiles({ image: banner.image });
  //         setEditingId(id);
  //       }
  //   };

  const handleRemoveBanner = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get(`/banner/delete/${id}`);
      if (response.status === 200) {
        toast.success('Banner apagar com sucesso');
        getBanners();
      }
    } catch (error) {
      console.error('Erro ao apagar o Banner:', error);
      toast.error('Algo deu errado');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line
  }, []);
  return (
    <HomeMidleBannerManagerUI
      banners={banners}
      formData={formDataMidle}
      formErrors={formErrorsMidle}
      formNameFilesMidle={formNameFilesMidle}
      onSubmitRegister={handleSubmitMidle}
      onChangeFormInput={onChangeFormInputMidle}
      onChangeFormFileInput={handleOnChangeFileInputSecurary}
      //   onEditBanner={handleEditBanner}
      onRemoveBanner={handleRemoveBanner}
    />
  );
};
