import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Product } from 'src/model/Product';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { ProductUI } from './ui';
import Client from 'src/model/User';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import { CartGrade, CartProduct } from 'src/model/CartProduct';
import { Grade } from 'src/model/Grade';

interface Params {
  id: string;
}

interface ProductAddCart {
  id: string;
  product: CartProduct;
  quantity: number;
  totalValue: number;
}

export interface SelectGrade {
  color: string;
  id: string;
}

export const ProductScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<Params>();
  const [products, setProdutos] = useState<Product>();
  const [quantity, setQuantity] = useState<number>(1);
  const dispatch = useDispatch();
  const [users, setUsers] = useState<Client>({} as Client);
  const [shippingCost, setShippingCost] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<SelectGrade>(
    {} as SelectGrade
  );
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [zoomStyle, setZoomStyle] = useState<React.CSSProperties>({});

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setZoomStyle({
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: '200%', // Ajuste o nível de zoom aqui
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({});
  };

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product>(`/product/one/${id}`);
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        const addImagesGrade: string[] = [];
        data.grade.forEach((grade) => {
          if (grade.image) {
            addImagesGrade.push(
              'https://sandbox.buunpsgpsystem.com.br/uploads/' + grade.image
            );
          }
        });
        data.imagens = [...data.imagens, ...addImagesGrade];
        setProdutos(data);
        setSelectedImage(data.imagens[0]);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleColorSelect = (color?: string, image?: string, id?: string) => {
    if (color && id) {
      setSelectedColor({ color, id });
    }
    setSelectedSize('');
    if (image) {
      setSelectedImage(image);
    }
  };

  const handleSizeSelect = (size: string) => {
    setSelectedSize(size);
  };

  //   const {
  //     formData: formDataReview,
  //     formErrors: formErrorsReview,
  //     setErrors: setErrorsReview,
  //     onChangeFormInput: onChangeFormInputReview,
  //     isFormValid: isFormValidReview,
  //     resetForm: resetFormReview,
  //   } = useForm({
  //     initialData: {
  //       description: '',
  //     },
  //     validators: {
  //       description: [validators.required],
  //     },
  //   });

  //   const handleChangeRating = (value: number): void => {
  //     if (value) {
  //       setRating(value);
  //     }
  //   };

  //   const handleOnSubmit = async (e: FormEvent): Promise<void> => {
  //     try {
  //       e.preventDefault();
  //       if (isFormValidReview()) {
  //         dispatch(setLoading(true));
  //         if (rating && rating < 0) {
  //           setErrorsReview({
  //             [FormInputReview.description]: ['Escola uma avaliação'],
  //           });
  //           toast.error('Escolha uma avaliação');
  //         }
  //         if (products) {
  //           const payload: ProductReviewBasic = {
  //             score: rating ? rating : 0,
  //             description: formDataReview[FormInputReview.description],
  //             product: products,
  //           };

  //           console.log(payload);
  //           const response = await api.post<ProductReview>('/review', payload);
  //           if (response.status === 201 || response.status === 200) {
  //             resetFormReview();
  //             setRating(0);
  //             toast.success('Dados salvos com sucesso!');
  //           } else if (response.status === 400) {
  //             const errorMessage =
  //               typeof response.data === 'string'
  //                 ? response.data
  //                 : JSON.stringify(response.data);
  //             toast.error(errorMessage);
  //           }
  //         } else {
  //           setErrorsReview({
  //             [FormInputReview.description]: ['Produto não selecionado'],
  //           });
  //           toast.error('Produto não selecionado');
  //         }
  //       } else {
  //         toast.error('Verifique os dados');
  //       }
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     } catch (error: any) {
  //       let errorMessage =
  //         'Falha ao realizar o cadastro, tente novamente mais tarde';
  //       if (error.response && error.response.data) {
  //         errorMessage =
  //           typeof error.response.data === 'string'
  //             ? error.response.data
  //             : JSON.stringify(error.response.data);
  //       }
  //       toast.error(errorMessage);
  //       setErrorsReview({
  //         name: [errorMessage],
  //       });
  //     } finally {
  //       dispatch(setLoading(false));
  //     }
  //   };

  const handleQuantityChange = (increment: boolean) => {
    setQuantity((prev) => Math.max(1, prev + (increment ? 1 : -1)));
  };

  const getPrice = (
    grade: Grade[],
    selectedColor: string,
    selectedSize: string
  ): number => {
    let price = 0;
    for (let i = 0; i < grade.length; i++) {
      for (let s = 0; s < grade[i].gradeSizes.length; s++) {
        if (
          selectedColor === grade[i].color &&
          selectedSize === grade[i].gradeSizes[s].size
        ) {
          price = grade[i].gradeSizes[s].price.discount
            ? grade[i].gradeSizes[s].price.discount
            : grade[i].gradeSizes[s].price.price;
        }
      }
    }
    return price;
  };

  const handleAddCart = async (): Promise<void> => {
    if (products && quantity > 0) {
      try {
        dispatch(setLoading(true));
        let isGradeSelect = true;
        products.grade.forEach((grade) => {
          if (grade.name === 'UNICO' && grade.color === 'UNICO') {
            isGradeSelect = false;
          }
        });
        if (isGradeSelect && !selectedColor) {
          toast.error('Selecione uma cor');
          return;
        }
        if (isGradeSelect && !selectedSize) {
          toast.error('Selecione um tamanho');
          return;
        }

        const price = getPrice(
          products.grade,
          selectedColor.color,
          selectedSize
        );

        const CartGrade: CartGrade = {
          name: isGradeSelect ? '' : 'UNICO',
          color: isGradeSelect ? selectedColor.color : 'UNICO',
          size: isGradeSelect ? selectedSize : 'unico',
          price,
        };
        const CartPoduct: CartProduct = {
          id: products.id,
          name: products.name,
          quantity,
          totalValue: price * quantity,
          grade: CartGrade,
        };
        const payload: ProductAddCart = {
          id: isGradeSelect ? selectedColor.id : '',
          product: CartPoduct,
          quantity: quantity,
          totalValue: price * quantity,
        };

        console.log(payload);
        const response = await api.post('/order/add-carts', payload);
        if (response.status === 201 || response.status === 200) {
          toast.success('Produto adicionado ao carrinho');
        } else {
          toast.error('Erro ao adicionar produto ao carrinho');
        }
      } catch (error) {
        console.error('Erro ao adicionar produto ao carrinho:', error);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const getUser = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Client>('/user/findeone');
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar usuarios:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleShippingCalc = async (
    altura: string,
    largura: string,
    profundidade: string,
    peso: string
  ) => {
    try {
      const payload = { altura, largura, profundidade, peso };

      const response = await api.post('/product/calc-frete', payload);

      setShippingCost(toCurrency(response.data.frete));
    } catch (error) {
      console.error('Erro ao calcular frete:', error);
      setShippingCost('Erro ao calcular o frete.');
    }
  };

  useEffect(() => {
    const userAddress = users.address?.map(
      (user) => user.city === products?.seller.city
    );

    if (userAddress) {
      handleShippingCalc(
        products?.dimensions.height ?? '',
        products?.dimensions.width ?? '',
        products?.dimensions.depth ?? '',
        products?.dimensions.weight ?? ''
      );
    }
  }, [users.address, products?.dimensions, products?.seller.city]);

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {products ? (
        <ProductUI
          products={products}
          onAddCart={handleAddCart}
          quantity={quantity}
          quantityChange={handleQuantityChange}
          user={users}
          shippingCost={shippingCost}
          selectedImage={selectedImage}
          selectedColor={selectedColor}
          selectedSize={selectedSize}
          handleColorSelect={handleColorSelect}
          handleSizeSelect={handleSizeSelect}
          zoomStyle={zoomStyle}
          handleMouseMove={handleMouseMove}
          handleMouseLeave={handleMouseLeave}
        />
      ) : (
        <p>Carregando produto...</p>
      )}
    </>
  );
};
