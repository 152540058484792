import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { NameFiles } from 'src/features/admin/components/Categories';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { StoreProfileManagerUI } from './ui';
import { getBoolean } from 'src/helpers/common/localStorage';
import { REACT_APP_AUTH } from 'src/utils/config';
import { Store } from 'src/model/Store';

export const StoreProfileManager: React.FC = () => {
  const dispatch = useDispatch();
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const [selectedStoreImage, setSelectedStoreImage] = useState<string>();
  const [selectedCoverImage, setSelectedCoverImage] = useState<string>();
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const [isEditingStoreImage, setIsEditingStoreImage] = useState(false);
  const [isEditingCoverImage, setIsEditingCoverImage] = useState(false);

  const handleToggleStoreEdit = () =>
    setIsEditingStoreImage(!isEditingStoreImage);
  const handleToggleCoverEdit = () =>
    setIsEditingCoverImage(!isEditingCoverImage);

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  const getStore = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Store>(`/user/findeone`);

        if (response.status === 200) {
          setSelectedStoreImage(response.data.imageBanner);
          setSelectedCoverImage(response.data.imageDetail);
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const {
    formData: formDataStore,
    formErrors: formErrorStore,
    setErrors: setErrorStore,
    onChangeFormInput: onChangeFormInputStore,
    isFormValid: isFormValidStore,
    resetForm: resetFormStore,
  } = useForm({
    initialData: {
      name: '',
      document: '',
      email: '',
      gender: '',
      date: '',
      phone: '',
      password: '',
      confirmPassword: '',
      terms: '',
      zipCode: '',
      state: '',
      city: '',
      street: '',
      complement: '',
      number: '',
      neighborhood: '',
      imageBase64: '',
      country: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      gender: [validators.required],
      date: [
        validators.required,
        validators.birthday,
        validators.maxLength(10),
      ],
      phone: [validators.required, validators.mobilePhone],
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      terms: [validators.required],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      street: [validators.required],
      number: [validators.required],
      country: [validators.required],
      neighborhood: [validators.required],
    },
  });

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidStore()) {
        dispatch(setLoading(true));

        const payload = {};
        const response = await api.post<Store>('/user', payload);

        if (response.status === 201) {
          resetFormStore();
          getStore();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      //   resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorStore({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeFileInput =
    (inputName: string) => (file: File | undefined) => {
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputStore(inputName)(base64);
          }
        };
      } else {
        setErrorStore({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  useEffect(() => {
    getStore();
    // eslint-disable-next-line
  }, []);

  return (
    <StoreProfileManagerUI
      formData={formDataStore}
      formErrors={formErrorStore}
      selectedStoreImage={selectedStoreImage}
      formNameFiles={formNameFiles}
      selectedCoverImage={selectedCoverImage}
      onSubmitRegister={handleOnSubmitRegister}
      onChangeFormInput={onChangeFormInputStore}
      onChangeFormFileInput={handleOnChangeFileInput}
      isEditingStoreImage={isEditingStoreImage}
      isEditingCoverImage={isEditingCoverImage}
      onToggleStoreEdit={handleToggleStoreEdit}
      onToggleCoverEdit={handleToggleCoverEdit}
    />
  );
};
