import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import {
  FormInputGrade,
  FormInputGradeSize,
} from 'src/features/createProduct/types/types';
import { UseFormReturn } from 'src/hooks';
import { Grade } from 'src/model/Grade';
import { GradeSize } from 'src/model/GradeSize';

interface GradeFormUI
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  variants: Grade[];
  filesImageGrade: File[];
  previewGradesSizes: GradeSize[];
  addVariant: (e: FormEvent) => void;
  removeVariant: (index: number) => void;
  // onChangeFormGradeSize: OnChangeFormInput;
  onChangeFormFileInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addSize: (e: FormEvent) => void;
  previewFilesGrade: string;
}

export const GradeForm: React.FC<GradeFormUI> = ({
  formData,
  formErrors,
  variants,
  filesImageGrade,
  previewFilesGrade,
  previewGradesSizes,
  onChangeFormInput,
  addVariant,
  removeVariant,
  onChangeFormFileInput,
  addSize,
}): JSX.Element => {
  // const imageBase64Grade = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <h1 className="text-2xl font-bold text-gray-800 mb-4">
        Variação do Produto{' '}
        <span className="text-sm text-gray-500">
          (é necessário pelo menos uma variação)
        </span>
        <br />
        <InputText
          label=""
          name="hiddenField"
          type="hidden"
          id="hiddenField"
          value={formData.hiddenField}
          onChange={(e) =>
            onChangeFormInput(formData.hiddenField)(e.target.value)
          }
          error={formErrors.hiddenField && formErrors.hiddenField[0]}
        />
      </h1>

      {/* Formulário de Variação */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="border-2 border-gray-500 p-4 rounded-lg">
          <form>
            <InputText
              label="Nome"
              name="color"
              type="text"
              id="color"
              value={formData[FormInputGrade.color]}
              onChange={(e) =>
                onChangeFormInput(FormInputGrade.color)(e.target.value)
              }
              error={formErrors.color?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite a cor"
            />
            {/* <InputFile
              refInput={imageBase64Grade}
              name="imageBase64"
              label="Foto"
              fileName={formNameFilesGrade?.imageBase64Grade}
              onChange={(e) =>
                onChangeFormFileInput(FormInputGrade.imageBase64Grade)(
                  e.target.files?.[0]
                )
              }
              error={
                formErrors.imageBase64Grade && formErrors.imageBase64Grade[0]
              }
            /> */}

            <InputFile
              name="mediaFilesGrade"
              label="Imagens ou vídeo"
              multiple
              onChange={onChangeFormFileInput}
              error={
                filesImageGrade.length > 5 ? 'Máximo de 5 arquivos' : undefined
              }
            />

            <div className="flex space-x-2 mt-2">
              {previewFilesGrade && (
                <img
                  src={previewFilesGrade}
                  alt={`File ${previewFilesGrade}`}
                  className="w-24 h-24 object-cover rounded"
                />
              )}
            </div>

            <span className="text-sm text-red-500 items-center text-center block mt-4">
              Atenção para criar variações:
            </span>

            <span className="text-sm text-red-500 block mt-4">
              <ul className="list-disc ml-4">
                <li>
                  Cada variação deve ter no minimo um tamanho, podendo ser
                  generico
                </li>
                <li>
                  Adicione quantos tamanhos forem nescessario para depois
                  Adicionar a Variação
                </li>
                <li>
                  Imagem deve ter uma das seguintes extens es: jpeg, jpg ou png
                </li>
              </ul>
            </span>
          </form>
        </div>

        <div className="border-2 border-gray-500 p-4 rounded-lg">
          <form>
            <InputText
              label="Tamanho"
              name="size"
              type="text"
              id="size"
              value={formData[FormInputGradeSize.size]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.size)(e.target.value)
              }
              error={formErrors.size?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o preço"
            />
            <InputText
              label="Valor unitário"
              name="price"
              type="number"
              id="price"
              value={formData[FormInputGradeSize.price]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.price)(e.target.value)
              }
              error={formErrors.price?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o preço"
            />
            <InputText
              label="Valor + Desconto"
              name="discount"
              type="number"
              id="discount"
              value={formData[FormInputGradeSize.discount]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.discount)(e.target.value)
              }
              error={formErrors.discount?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o valor com desconto"
            />
            <InputText
              label="Estoque"
              name="stock"
              type="number"
              id="stock"
              value={formData[FormInputGradeSize.stock]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.stock)(e.target.value)
              }
              error={formErrors.stock?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite a quantidade em estoque"
            />
            <div className="flex justify-center mt-2">
              <Button
                type="button"
                title="+ Adicionar tamanho"
                buttonStyle="primary"
                size="sm"
                onClick={addSize}
                className="text-red-500"
              />
            </div>
          </form>

          <span className="text-sm text-gray-500 items-center text-center block">
            {previewGradesSizes && previewGradesSizes.length > 0
              ? previewGradesSizes.map(
                  (sizes, index) => sizes && <div key={index}>{sizes.size}</div>
                )
              : 'Nem um tamanho foi criado'}
          </span>
        </div>
      </div>

      <div className="grid content-center">
        <Button
          type="button"
          title="+ Adicionar Variação"
          buttonStyle="text"
          size="sm"
          onClick={addVariant}
          className="text-red-500"
        />
      </div>

      {/* Lista de Variações */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
        {variants?.length > 0 ? (
          variants.map((variant, index) => (
            <div
              key={index}
              className="p-2 bg-white shadow-md rounded-lg border border-red-200 transition-transform hover:scale-102"
            >
              <div className="text-lg font-bold text-red-600 mb-1">
                <span className="font-semibold text-black">Cor:</span>{' '}
                {variant.color}
              </div>
              {variant.image && (
                <img
                  src={variant.image}
                  alt={`File ${previewFilesGrade}`}
                  className="w-24 h-24 object-cover rounded"
                />
              )}
              <div className="text-sm text-red-600 mb-1">
                {variant.gradeSizes?.map((size) => (
                  <>
                    <div className="flex felx-row">
                      <span className="font-semibold text-black">
                        Tamanho:{' '}
                      </span>
                      <div key={size.id}> {' ' + size.size}</div>
                    </div>

                    <div className="text-sm text-red-600 mb-1">
                      <span className="font-semibold text-black">Preço:</span>{' '}
                      R$ {size.price.price}
                    </div>
                    <div className="text-sm text-red-600 mb-1">
                      <span className="font-semibold text-black">
                        Valor + Desconto:
                      </span>{' '}
                      R$ {size.price.discount}
                    </div>
                    <div className="text-sm text-red-600 mb-3">
                      <span className="font-semibold text-black">Estoque:</span>{' '}
                      {size.stock}
                    </div>
                    <hr />
                  </>
                ))}
              </div>

              <Button
                type="button"
                title="- Excluir"
                buttonStyle="outline"
                size="sm"
                onClick={() => removeVariant(index)}
                className="text-red-500"
              />
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-center col-span-full">
            Nenhuma variação cadastrada ainda.
          </p>
        )}
      </div>
    </>
  );
};
