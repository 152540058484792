import React, { useEffect, useState } from 'react';
import { Product } from 'src/model/Product';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { Banners } from 'src/model/Banners';
import { ProductsSearchUI } from '../../components/ProductsUI';
import { useParams } from 'react-router-dom';
import { toCurrency } from 'src/helpers/currency/toCurrency';

interface Params {
  keyword: string;
}
export const ProductsFindScreen: React.FC = (): JSX.Element => {
  const { keyword } = useParams<Params>();
  const [products, setProducts] = useState<Product[]>([]);
  const dispatch = useDispatch();
  const [banners, setBanners] = useState<Banners[]>([]);

  const getBannersHome = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/PRIMARY_HOME'
      );
      if (response.status === 200) {
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getProduct = async (): Promise<void> => {
    if (keyword) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Product[]>(`/product/find/${keyword}`);
        if (response.status === 200) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      console.error('Parâmetro keyword não fornecido');
    }
  };
  const handleValuePrice = (product: Product, discount: boolean): string => {
    let price: string = '';

    if (product.grade) {
      outerLoop: for (const grade of product.grade) {
        if (grade.gradeSizes) {
          for (const gradeSize of grade.gradeSizes) {
            if (gradeSize.price) {
              if (discount) {
                // eslint-disable-next-line
                product.promotion
                  ? (price = toCurrency(gradeSize.price.discount))
                  : (price = toCurrency(gradeSize.price.price));
              } else {
                price = toCurrency(gradeSize.price.price);
              }
              break outerLoop;
            }
          }
        }
      }
    }
    return price;
  };

  useEffect(() => {
    if (keyword) {
      getProduct();
    }
    // eslint-disable-next-line
  }, [keyword]);

  useEffect(() => {
    getBannersHome();
    // eslint-disable-next-line
  }, []);

  return (
    <ProductsSearchUI
      banners={banners}
      product={products}
      valuePrice={handleValuePrice}
    />
  );
};
