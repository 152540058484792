import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Product, ProductStatus } from 'src/model/Product';
import { api } from 'src/services/api';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { toast } from 'react-toastify';
import { toCurrency } from 'src/helpers/currency/toCurrency';

const ProductManager: React.FC = () => {
  const history = useHistory();
  const [products, setProducts] = React.useState<Product[]>([]);
  const dispatch = useDispatch();

  // Handle edit product
  const handleEdit = (id: string) => {
    history.push(`/editar-produto/${id}`);
  };

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product[]>('/product/find-store');
      if (response.status === 200) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDelete = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      // é um endpoint do tipo void.
      await api.get(`/product/delete/${id}`);
      getProduct();
      toast('Produto deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar produto:', error);
      toast('Erro ao deletar o produto');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-wrap gap-6 m-2">
      {products &&
        products.map(
          (product) =>
            product.status === ProductStatus.ACTIVE && (
              <div
                key={product.id}
                className="border border-gray-100 rounded-lg shadow-md bg-white relative w-[12rem]"
              >
                <div className="h-32 md:h-40 bg-white flex items-center justify-center overflow-hidden">
                  {product.imagens && product.imagens.length > 0 ? (
                    <img
                      src={product.imagens[0]}
                      alt={product.name}
                      className="md:p-0 p-1 h-full w-full object-contain transform transition-transform duration-300 group-hover:scale-110"
                    />
                  ) : (
                    <div className="text-gray-400 text-sm">
                      Imagem indisponível
                    </div>
                  )}
                </div>

                <div className="flex flex-col px-3 mt-2">
                  <h2
                    className="text-lg font-semibold text-gray-800 leading-tight overflow-hidden"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      overflow: 'hidden',
                    }}
                    title={product.name}
                  >
                    {product.name}
                  </h2>

                  {product.price.discount !== null &&
                  product.promotion !== false ? (
                    <div
                      className={`flex flex-col ${
                        toCurrency(product.price.discount).length +
                          toCurrency(product.price.price).length >
                        22
                          ? 'flex-col'
                          : 'sm:flex-row sm:items-center sm:space-x-2'
                      } mt-2`}
                    >
                      {/* Preço Promocional */}
                      <span className="text-red-500 font-bold text-lg">
                        {toCurrency(product.price.discount)}
                      </span>
                      {/* Preço Original */}
                      <span className="text-gray-500 text-xs line-through">
                        {toCurrency(product.price.price)}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mt-2">
                      <span className="text-red-500 font-bold text-lg">
                        {toCurrency(product.price.price)}
                      </span>
                    </div>
                  )}

                  <p className="text-sm text-gray-500 mb-1">
                    Estoque:{' '}
                    <span className="text-red-500 font-semibold">
                      {product.stock}
                    </span>
                  </p>

                  {/* Action buttons */}
                  <div className="w-full flex justify-around items-center space-x-2 my-2">
                    <button
                      onClick={() => handleEdit(product.id)}
                      className="flex items-center text-md text-blue-500 hover:text-blue-600 transition-transform duration-300 transform hover:scale-105"
                    >
                      <FaEdit className="mr-1" /> Editar
                    </button>
                    <button
                      onClick={() => handleDelete(product.id)}
                      className="flex items-center text-md text-red-500 hover:text-red-600 transition-transform duration-300 transform hover:scale-105"
                    >
                      <FaTrash className="mr-1" /> Excluir
                    </button>
                  </div>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default ProductManager;
