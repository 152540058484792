import React, { FormEvent, useEffect, useState } from 'react';
import { Banners } from 'src/model/Banners';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { useDispatch } from 'react-redux';
import { api } from 'src/services/api';
import { setLoading } from 'src/redux/loading/loadingSlice';

import { HomeThirdBannerManagerUI } from './ui';
import { BannerBasic, FormInputBannerService } from '../../types';
import { BannerType } from 'src/model/BannerType';
import { toast } from 'react-toastify';

export interface NameFiles {
  [key: string]: string;
}

export const HomeThirdBannerManagerScreen: React.FC = () => {
  const [banners, setBanners] = useState<Banners[]>([]);
  const dispatch = useDispatch();
  //   const { loading } = useSelector(useLoading);
  const [formNameFilesBannerService, setFormNameFilesBannerService] = useState<{
    [key: string]: string;
  }>({});

  const getBanners = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Banners[]>(
        '/banner/find-all/SERVICE_HOME'
      );
      if (response.status === 200) {
        setBanners(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar banners:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataService,
    formErrors: formErrorsService,
    setErrors: setErrorsService,
    onChangeFormInput: onChangeFormInputService,
    isFormValid: isFormValidService,
    resetForm: resetFormService,
  } = useForm({
    initialData: {
      altTextService: '',
      imageService: '',
      linkService: '',
    },
    validators: {
      altTextService: [validators.required],
      imageService: [validators.required],
      linkService: [validators.required],
    },
  });

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidService()) {
        dispatch(setLoading(true));
        const payload: BannerBasic = {
          altText: formDataService[FormInputBannerService.altTextService],
          image: formDataService[FormInputBannerService.imageService],
          link: formDataService[FormInputBannerService.linkService],
          privatetype: BannerType.SERVICE_HOME,
        };

        const response = await api.post<Banners>('/banner', payload);
        if (response.status === 201 || response.status === 200) {
          getBanners();
          resetFormService();
          setFormNameFilesBannerService({});
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        resetFormService();
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorsService({
        name: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeFormFileInputService =
    (inputName: string) =>
    (file: File | undefined): void => {
      if (file && file.type.match(/image\/(jpg|jpeg|png|webp)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFilesBannerService({
              ...formNameFilesBannerService,
              [inputName]: file.name,
            });
            onChangeFormInputService(inputName)('');
            onChangeFormInputService(inputName)(base64);
          }
        };
      } else {
        setErrorsService({
          [inputName]: ['O formato deve ser .jpg, .jpeg, .png ou .webp'],
        });
      }
    };

  //   const handleEditBanner = (id: string) => {
  //     console.log(id);
  //       const banner = banners.find((b) => b.id === id);
  //       if (banner) {
  //         setFormData({ image: banner.image, altText: banner.altText });
  //         setFormNameFiles({ image: banner.image });
  //         setEditingId(id);
  //       }
  //   };

  const handleRemoveBanner = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get(`/banner/delete/${id}`);
      if (response.status === 200) {
        toast.success('Banner apagar com sucesso');
        getBanners();
      }
    } catch (error) {
      console.error('Erro ao apagar o Banner:', error);
      toast.error('Algo deu errado');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line
  }, []);

  return (
    <HomeThirdBannerManagerUI
      banners={banners}
      formData={formDataService}
      formErrors={formErrorsService}
      formNameFilesService={formNameFilesBannerService}
      onSubmitRegister={handleSubmit}
      onChangeFormInput={onChangeFormInputService}
      onChangeFormFileInputService={handleOnChangeFormFileInputService}
      //   onEditBanner={handleEditBanner}
      onRemoveBanner={handleRemoveBanner}
    />
  );
};
