// src/components/ReviewForm.tsx
import React, { FormEvent } from 'react';
import { FormInputReview } from 'src/features/product/types';
import { UseFormReturn } from 'src/hooks';
import { StarRating } from '../StartRating';
import TextArea from '../TextArea';
import { Button } from '../Button';
export interface ReviewFormProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitRegister: (e: FormEvent) => void;
  rating: number;
  onChangeRating: (value: number) => void;
}

export const ReviewForm: React.FC<ReviewFormProps> = ({
  formData,
  formErrors,
  rating,
  onChangeFormInput,
  onSubmitRegister,
  onChangeRating,
}) => {
  return (
    <form
      onSubmit={onSubmitRegister}
      className="bg-white p-2 rounded-lg  w-full "
    >
      <h3 className="text-xl font-bold mb-4">Deixe sua avaliação</h3>

      {/* Componente de Seleção de Estrelas */}
      <div className="mb-4">
        <StarRating rating={rating} ChangeRating={onChangeRating} />
      </div>

      {/* Campo de Texto para Comentário */}
      <div className="mb-4">
        <TextArea
          name="description"
          label="Avaliação"
          placeholder="Escreva seu comentário aqui..."
          value={formData[FormInputReview.description]}
          onChange={(e) =>
            onChangeFormInput(FormInputReview.description)(e.target.value)
          }
          error={formErrors.description && formErrors.description[0]}
          textAreaClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
      </div>

      {/* Botão de Enviar */}
      <div className="w-full flex justify-center">
        <Button
          type="submit"
          title="Enviar Avaliação"
          buttonStyle="red"
          size="lg"
          className="w-[60%]"
          disabled={
            formData.description === '' ||
            rating === 0 ||
            Object.keys(formErrors).length > 0
          }
        />
      </div>
    </form>
  );
};
