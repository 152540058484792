import React from 'react';

interface StarRatingProps {
  maxRating?: number;
  rating: number;
  ChangeRating: (value: number) => void;
}

export const StarRating: React.FC<StarRatingProps> = ({
  maxRating = 5,
  rating,
  ChangeRating,
}) => {
  return (
    <div className="rating flex space-x-1 cursor-pointer">
      {[...Array(maxRating)].map((_, index) => (
        <svg
          key={index}
          className={`w-6 h-6 transition-colors duration-200 ${
            index + 1 <= rating ? 'text-yellow-400' : 'text-gray-300'
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
          onClick={() => ChangeRating(index + 1)}
        >
          <path d="M9.049 2.927a.998.998 0 011.902 0l1.92 4.46 4.908.07a1 1 0 01.554 1.784l-3.68 3.22 1.12 4.831a1 1 0 01-1.512 1.05L10 15.15l-4.36 2.194a1 1 0 01-1.512-1.05l1.12-4.831-3.68-3.22a1 1 0 01.554-1.784l4.908-.07 1.92-4.46z" />
        </svg>
      ))}
    </div>
  );
};
