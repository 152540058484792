import React from 'react';
import { InputText } from 'src/components/InputText';
import { UseFormReturn } from 'src/hooks';
import { Summary } from '..';

interface OrderSummaryProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  summary: Summary;
  onContinueToPayment: () => void;
  onReturnToShopping: () => void;
  couponCode?: string;
  discount: number;
  totalAfterDiscount?: number;
  fetchCouponDiscount: (code: string) => void;
}

export enum FormInputCouponDiscount {
  code = 'code',
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  formData,
  formErrors,
  summary,
  discount,
  totalAfterDiscount,
  onChangeFormInput,
  onContinueToPayment,
  onReturnToShopping,
  fetchCouponDiscount,
}) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full border border-gray-200">
      <h2 className="text-lg font-semibold mb-4">Resumo do Pedido</h2>

      <div className="space-y-2">
        <div className="flex justify-between">
          <span className="text-gray-600">Preço original</span>
          <span className="font-semibold">
            {Number(summary.originalPrice).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Economia</span>
          <span className="font-semibold text-green-500">
            {Number(summary.savings).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Retirada na Loja</span>
          <span className="font-semibold">
            {Number(summary.storePickup).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Imposto</span>
          <span className="font-semibold">
            {Number(summary.tax).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>
      </div>
      <div className="mt-4 flex justify-between items-center">
        <label htmlFor="coupon" className="block text-sm text-gray-600">
          Cupom de Desconto
        </label>
        <div className="w-full max-w-xs">
          <InputText
            id="coupon"
            name="code"
            type="text"
            value={formData[FormInputCouponDiscount.code]}
            onChange={(e) => {
              onChangeFormInput(FormInputCouponDiscount.code)(e.target.value);
              fetchCouponDiscount(e.target.value);
            }}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Digite o código do cupom"
            error={formErrors.code && formErrors.code[0]}
          />
        </div>
        {discount > 0 && (
          <div className="mt-2 text-green-500 font-semibold">
            Desconto aplicado:{' '}
            {discount.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </div>
        )}
      </div>

      <div className="border-t my-4"></div>
      <div className="flex justify-between mb-4">
        <span className="font-bold">Total</span>
        <span className="font-bold text-lg">
          {Number(totalAfterDiscount).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </div>

      <button
        onClick={onContinueToPayment}
        className="w-full bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 focus:outline-none"
      >
        Pagar
      </button>
      <div className="text-center mt-4">
        <button
          onClick={onReturnToShopping}
          className="text-red-500 text-sm font-semibold hover:underline"
        >
          Voltar às Compras
        </button>
      </div>
    </div>
  );
};
