// src/features/admin/components/AppQrCodeManager/index.tsx
import React, { FormEvent, useEffect, useState } from 'react';
import { AppQrCodeManagerUI } from './ui';
import { QrCodeData } from 'src/model/QrCode';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { useDispatch } from 'react-redux';
import { api } from 'src/services/api';

export interface NameFiles {
  [key: string]: string;
}
export const AppQrCodeManagerScreen: React.FC = () => {
  const [qrCode, setQrCode] = useState<QrCodeData>();
  const dispatch = useDispatch();
  //   const { loading } = useSelector(useLoading);
  const [formNameFiles, setFormNameFiles] = useState<{ [key: string]: string }>(
    {}
  );

  const getQrCode = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<QrCodeData>('/qrcode/find-all');
      if (response.status === 200) {
        setQrCode(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
      isFormValidQrCodeData();
      resetFormQrCodeData();
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataQrCodeData,
    formErrors: formErrorsQrCodeData,
    setErrors: setErrorsQrCodeData,
    onChangeFormInput: onChangeFormInputQrCodeData,
    isFormValid: isFormValidQrCodeData,
    resetForm: resetFormQrCodeData,
  } = useForm({
    initialData: {
      imageUrl: '',
      link: '',
    },
    validators: {
      name: [validators.required],
    },
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({
              ...formNameFiles,
              [inputName]: file.name,
            });
            onChangeFormInputQrCodeData(inputName)('');
            onChangeFormInputQrCodeData(inputName)(base64);
          }
        };
      } else {
        setErrorsQrCodeData({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  useEffect(() => {
    getQrCode();
    // eslint-disable-next-line
  }, []);
  return (
    <AppQrCodeManagerUI
      qrCode={qrCode}
      formData={formDataQrCodeData}
      formErrors={formErrorsQrCodeData}
      formNameFiles={formNameFiles}
      onSubmitRegister={handleSubmit}
      onChangeFormInput={onChangeFormInputQrCodeData}
      onChangeFormFileInput={handleOnChangeFileInput}
      //   handleEditBanner={handleEditQrCodeData}
      //   handleRemoveBanner={handleRemoveQrCodeData}
    />
  );
};

export default AppQrCodeManagerScreen;
