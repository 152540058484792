import React from 'react';
import { Route } from 'src/navigation/Route';
import { NotificationsScreen } from './screens/Notifications';

export const NOTIFICATIONS_ROUTES = {
  itself: '/notifications',
};

export const NotificationsNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={NOTIFICATIONS_ROUTES.itself}
      component={NotificationsScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
