import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { NameFiles } from 'src/features/admin/components/Categories';
import { updateMask as updateMaskPhone } from 'src/helpers/masks/mobilePhone';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import Client from 'src/model/User';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { UserProfileManagerUI } from './ui';
// import { UserBasic } from 'src/model/UserBasic';
import { FormInputNameRegister } from 'src/features/core/auth/types';

// Interface para os dados do usuário
interface User {
  userData: Client;
}

export const UserProfileManagerScreen: React.FC<User> = ({ userData }) => {
  const dispatch = useDispatch();

  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const [selectedImage, setSelectedImage] = useState<string>();
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [shouldShowPasswordToText, setShouldShowPasswordToText] =
    useState(false);
  const [shouldShowPasswordToText2, setShouldShowPasswordToText2] =
    useState(false);
  const handleOnTogglePasswordToText = (): void =>
    setShouldShowPasswordToText(!shouldShowPasswordToText);

  const handleOnTogglePasswordToText2 = (): void =>
    setShouldShowPasswordToText2(!shouldShowPasswordToText2);
  const handleToggleEdit = () => setIsEditingImage(!isEditingImage);
  const factoryUser = (): void => {
    if (userData) {
      onChangeFormInputRegister(FormInputNameRegister.name)(userData.name);
      onChangeFormInputRegister(FormInputNameRegister.email)(userData.email);
      onChangeFormInputRegister(FormInputNameRegister.phone)(
        userData.cellPhone
      );
      onChangeFormInputRegister(FormInputNameRegister.password)(
        userData.password || ''
      );
      setSelectedImage(userData.image);
    }
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValid,
    resetForm: resetForm,
  } = useForm({
    initialData: {
      name: '',
      email: '',
      phone: '',
      password: '',
      imageBase64: '',
    },
    validators: {
      name: [validators.required],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
    },
    formatters: {
      phone: updateMaskPhone,
    },
  });

  //   const showModal = ({ value, title }: ShouldShowModalProps): void => {
  //     onSetVisible(true);
  //   };

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputRegister(inputName)('');
            onChangeFormInputRegister(inputName)(base64);
          }
        };
      } else {
        setErrorRegister({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValid()) {
        dispatch(setLoading(true));

        const payload: Client = {
          id: userData.id,
          name: formDataRegister[FormInputNameRegister.name],
          cpf: userData.cpf,
          rg: userData.rg,
          cellPhone: formDataRegister[FormInputNameRegister.phone],
          email: formDataRegister[FormInputNameRegister.email],
          password: formDataRegister[FormInputNameRegister.password],
          gender: userData.gender,
          birthDate: userData.birthDate,
          motherName: userData.motherName,
          image: formDataRegister[FormInputNameRegister.imageBase64],
          type: userData.type,
        };
        const response = await api.post<Client>('/user/update', payload);

        if (response.status === 200 || response.status === 201) {
          resetForm();
          factoryUser();
          toast.success('Dados salvos com sucesso!');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      //   resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    // getClient();
    factoryUser();
    // eslint-disable-next-line
  }, []);

  return (
    <UserProfileManagerUI
      formData={formDataRegister}
      formErrors={formErrorRegister}
      formNameFiles={formNameFiles}
      onChangeFormInput={onChangeFormInputRegister}
      onSubmitRegister={handleOnSubmitRegister}
      onChangeFormFileInput={handleOnChangeFileInput}
      shouldShowPasswordToText={shouldShowPasswordToText}
      shouldShowPasswordToText2={shouldShowPasswordToText2}
      onTogglePasswordToText={handleOnTogglePasswordToText}
      onTogglePasswordToText2={handleOnTogglePasswordToText2}
      selectedImage={selectedImage}
      isEditingImage={isEditingImage}
      onToggleEdit={handleToggleEdit}
    />
  );
};
