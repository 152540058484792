import { REACT_APP_WEBSOCKET_URL } from 'src/utils/config';

const WEBSOCKET_URL =
  REACT_APP_WEBSOCKET_URL || 'ws://192.168.0.5:3001//notifications';

class WebSocketService {
  private socket: WebSocket | null = null;

  connect(userId: string) {
    if (this.socket) {
      console.warn('WebSocket already connected');
      return;
    }

    this.socket = new WebSocket(`${WEBSOCKET_URL}`);

    this.socket.onopen = () => {
      this.socket?.send(JSON.stringify({ userId }));
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error', error);
    };
  }

  sendMessage(message: string) {
    if (this.socket) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not connected');
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(callback: (data: any) => void) {
    if (this.socket) {
      this.socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);

          // Valida se os campos esperados estão presentes
          if (parsedData.eventType && parsedData.message && parsedData.userId) {
            callback(parsedData);
          } else {
            console.warn('Mensagem com formato inesperado:', parsedData);
          }
        } catch (error) {
          console.error(
            'Erro ao processar mensagem do WebSocket:',
            event.data,
            error
          );
        }
      };
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}

export const websocketService = new WebSocketService();
