import React from 'react';
import { ProductCard3 } from 'src/components/ProductCard3';
import { Category } from 'src/model/Category';
import { Product } from 'src/model/Product';

interface SidebarWithProductsUIProps {
  categories: {
    categories: Category[];
    activeCategoryId: string | null;
    onCategorySelect: (categoryId: string | null) => void;
  };
  products: {
    products: Product[];
  };
  valuePrice: (product: Product, discount: boolean) => string;
}

export const SidebarWithProductsUI: React.FC<SidebarWithProductsUIProps> = ({
  categories: { categories, activeCategoryId, onCategorySelect },
  products: { products },
  valuePrice,
}) => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Sidebar */}
      <aside className="md:w-44 border-r border-red-200 p-4 min-h-max">
        <ul className="space-x-4 md:space-x-0 md:space-y-4 flex md:block overflow-x-auto">
          {categories.map((category) => (
            <li
              key={category.id}
              className={`border-l-0 md:border-l-4 border-b-4 md:border-b-0 pl-2 md:pl-1 pb-2 md:pb-0 text-gray-700 cursor-pointer whitespace-nowrap text-wrap ${
                activeCategoryId === category.id
                  ? 'border-red-500'
                  : 'border-transparent hover:border-red-600'
              }`}
              onClick={() => onCategorySelect(category.id)}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </aside>

      {/* Main Content */}
      <div className="w-full flex-1 px-6 py-3">
        <h1 className="text-2xl font-bold">
          {categories.find((cat) => cat.id === activeCategoryId)?.name ||
            'Todos'}
        </h1>
        <div className="mt-4">
          {products.length > 0 ? (
            <ProductCard3 products={products} valuePrice={valuePrice} />
          ) : (
            <p>Nenhum produto encontrado para esta categoria.</p>
          )}
        </div>
      </div>
    </div>
  );
};
