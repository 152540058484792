// components/HomeSecondaryBannerManagerUI.tsx
import React, { FormEvent } from 'react';
import { InputText } from 'src/components/InputText';
import { InputFile } from 'src/components/InputFile';
import { Button } from 'src/components/Button';
import Banner from 'src/components/Banner';
import { FormInputBannerSecondaryBanner } from 'src/features/admin/types';
import { Banners } from 'src/model/Banners';
import { NameFiles } from '..';
import { UseFormReturn } from 'src/hooks';

interface HomeSecondaryBannerManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFilesSecondary?: NameFiles;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  //   onEditBanner: (id: string) => void;
  onRemoveBanner: (id: string) => void;
  banners: Banners[];
}

export const HomeSecondaryBannerManagerUI: React.FC<
  HomeSecondaryBannerManagerUIProps
> = ({
  banners,
  formData,
  formNameFilesSecondary,
  formErrors,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
  //   onEditBanner,
  onRemoveBanner,
}) => {
  const imageSecondaryBanner64 = React.useRef<HTMLInputElement>(null);

  return (
    <div className="mb-4 space-y-2 grid grid-cols-1 mt-3">
      <form onSubmit={onSubmitRegister} className="space-y-4">
        <div className="flex flex-wrap gap-5 lg:gap-10">
          <div className="flex flex-col">
            <InputFile
              refInput={imageSecondaryBanner64}
              name="imageBannerSecondary"
              label="Upload da Imagem"
              fileName={formNameFilesSecondary?.imageSecondaryBanner}
              onChange={(e) =>
                onChangeFormFileInput(
                  FormInputBannerSecondaryBanner.imageSecondaryBanner
                )(e.target.files?.[0])
              }
              error={
                formErrors.imageSecondaryBanner &&
                formErrors.imageSecondaryBanner[0]
              }
            />
            <span className="text-sm text-gray-500">
              (A imagem deve ter tamanho de 450x180)
            </span>
          </div>
          <InputText
            name="altTextSecondaryBanner"
            label="Texto Alternativo da Imagem"
            placeholder="Digite o texto alternativo..."
            value={
              formData[FormInputBannerSecondaryBanner.altTextSecondaryBanner]
            }
            onChange={(e) =>
              onChangeFormInput(
                FormInputBannerSecondaryBanner.altTextSecondaryBanner
              )(e.target.value)
            }
            error={
              formErrors.altTextSecondaryBanner &&
              formErrors.altTextSecondaryBanner[0]
            }
            inputClassName="rounded-lg p-2 w-[15rem] bg-[#fafafa] shadow-inner"
          />
        </div>

        <InputText
          name="linkBannerHome"
          label="Link"
          placeholder="Digite o link..."
          value={formData[FormInputBannerSecondaryBanner.linkSecondaryBanner]}
          onChange={(e) =>
            onChangeFormInput(
              FormInputBannerSecondaryBanner.linkSecondaryBanner
            )(e.target.value)
          }
          error={
            formErrors.linkSecondaryBanner && formErrors.linkSecondaryBanner[0]
          }
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <Button
          type="submit"
          title="Salvar Banner"
          buttonStyle="red"
          size="lg"
        />
      </form>
      <div className="overflow-x-auto pb-4">
        <div className="grid grid-rows-1 gap-4 auto-cols-[minmax(200px,_1fr)] grid-flow-col">
          {banners &&
            banners.map((banner) => (
              <div
                key={banner.id}
                className="relative border p-4 rounded-lg shadow-md"
              >
                <img
                  src={banner.image}
                  alt={banner.altText}
                  className="w-full h-32 object-cover rounded mb-2"
                />
                <p className="text-center text-sm">{banner.altText}</p>
                <div className="flex justify-between mt-2">
                  {/* <button
                onClick={() => onEditBanner(banner.id)}
                className="text-yellow-500"
              >
                Editar
              </button> */}
                  <button
                    onClick={() => onRemoveBanner(banner.id)}
                    className="text-red-500"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Componente de preview */}
      <div className="w-full">
        {banners.length > 0 ? (
          <Banner images={banners} interval={3000} />
        ) : (
          <p>Nenhuma pré-visualização disponível</p>
        )}
      </div>
    </div>
  );
};
