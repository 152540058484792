import React from 'react';
import { NotificationComponentScreen } from 'src/components/NotificationComponent';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';

export const NotificationsUI: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="w-[80%] bg-white mt-4 p-4 rounded-md border">
        <NotificationComponentScreen />
      </div>
    </AuthWrapper>
  );
};
