import React from 'react';

interface ProductSliderProps {
  imagens: string[];
  highlightedImage?: string;
  handleColorSelect: (color?: string, image?: string) => void;
  // hoverImg: string;
  zoomStyle: React.CSSProperties;
  handleMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleMouseLeave: () => void;
}

export const ProductSlider: React.FC<ProductSliderProps> = ({
  imagens,
  highlightedImage,
  // hoverImg,
  zoomStyle,
  handleColorSelect,
  handleMouseMove,
  handleMouseLeave,
}): JSX.Element => {
  const limitedImages = imagens.slice(0, 5);

  return (
    <div className="w-full flex flex-col-reverse items-center gap-2">
      <div
        className="w-full flex gap-5 overflow-y-auto py-2 justify-center"
        style={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {limitedImages.map((src, index) => (
          <div
            key={index}
            className="h-20 w-20 flex items-center justify-center shadow-md bg-white rounded-md"
          >
            <img
              src={src}
              alt={`Thumbnail ${index + 1}`}
              className="object-contain w-full h-full rounded-md cursor-pointer md:hover:scale-105"
              onClick={() => handleColorSelect(undefined, src)}
              onMouseEnter={() => handleColorSelect(undefined, src)}
              onMouseLeave={() => handleColorSelect(undefined, src)}
              //   onMouseLeave={() => setHoverImage(undefined)}
            />
          </div>
        ))}
      </div>
      <div
        className="w-full h-[300px] md:h-[500px] flex items-center justify-center rounded-md bg-white shadow-lg"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage: `url(${highlightedImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: zoomStyle.backgroundPosition || 'center',
          backgroundSize: zoomStyle.backgroundSize || 'contain',
          ...zoomStyle,
        }}
      ></div>
    </div>
  );
};
